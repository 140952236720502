import React from 'react';
import { Tooltip } from 'antd';
import HazardIndexGrouping from '../HazardIndexGrouping/HazardIndexGrouping';
import Item from '../Item/Item';
import Groups from '../Groups/Groups';
import NatCatMaps from '../NatCatMaps/NatCatMaps';

const TailoredList = ({ list = [], onCheck, onGroupCheck }) => {
  return (
    <div className="report-list">
      <ul>
        {list.map((item) => {
          const checked = list
            .filter((item) => item.checked)
            .map((item) => item.id)
            .includes(item.id);

          if (item?.children?.length) {
            if (item?.id === 'report-hazard-assessment') {
              return (
                <Item key={item.id} item={item}>
                  <HazardIndexGrouping appearance="list" />
                </Item>
              );
            }

            return <Groups key={item.id} item={item} onCheck={onCheck} onGroupCheck={onGroupCheck} />;
          }

          if (item?.id === 'report-natural-catastrophe') {
            return (
              <Tooltip title={item.disabled && 'Select a business location to view the NatCat analysis.'}>
                <Item
                  key={item.id}
                  item={item}
                  checked={checked}
                  onCheck={(e) => onCheck(e.target.checked, item.id)}
                  disabled={item.disabled}
                >
                  {checked && <NatCatMaps item={item} onGroupCheck={onGroupCheck} disabled={item.disabled} />}
                </Item>
              </Tooltip>
            );
          }

          return (
            <Item key={item.id} item={item} checked={checked} onCheck={(e) => onCheck(e.target.checked, item.id)} />
          );
        })}
      </ul>
    </div>
  );
};

export default TailoredList;
