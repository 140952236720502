import React from 'react';
import { Layout as AntLayout, BackTop } from 'antd';
import classNames from 'classnames';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import IndustryNavigation from '../../scenes/Industry/components/Navigation/Navigation';
import { themeSelector } from '../../scenes/Account/AccountSelectors';
import './Layout.scss';

export default ({ children, showMenu = false, showSearch = false, isHome = false }) => (
  <AntLayout className="layout">
    <Header isHome={isHome} theme={themeSelector()} />
    <AntLayout className={classNames('content', { home: isHome })}>
      {showMenu && <Menu />}
      {showSearch && <IndustryNavigation />}
      {children}
    </AntLayout>
    <Footer theme={themeSelector()} />
    <BackTop />
  </AntLayout>
);
