/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-computed-key */
import React, { useRef, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import classNames from 'classnames';
import { Progress } from 'antd';
import { ThemeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import { renderColor } from '../../../../../Industry/utils';
import './ReportComponent.scss';
import { countryCodeSelector } from '../../../../../Account/AccountSelectors';
import { indicativeRateCountryCodeSelector } from '../../../../../Industry/IndustrySelectors';
import { PageBreak } from '../../../../../../components/Print/Print';
import { SET_REPORT_PAGE_BREAK_REQUIRED, REMOVE_REPORT_PAGE_BREAK_REQUIRED } from '../../../../ReportActions';

export const Heading = ({ children, level = 1, seq, anchor }) => {
  const theme = useTheme(ThemeContext);
  if (level === 1) {
    return (
      <h1
        className="report-preview-heading"
        id={anchor}
        style={{
          backgroundImage: `linear-gradient(to right, ${theme.headings.h1?.background || theme.covers[0].bgColor[0]}, ${
            theme.headings.h1?.background || theme.covers[0].bgColor[1]
          })`,
          fontSize: theme.headings.h1.size,
          color: theme.headings.h1?.background
            ? theme.headings?.h1?.color
            : theme.headings?.pageHeading?.color || '#fff',
          fontFamily: theme.headings.h1.font,
        }}
      >
        <span>{seq}.</span>
        <span>{children}</span>
      </h1>
    );
  }
  if (level === 2) {
    return (
      <h2
        className="report-preview-heading"
        id={anchor}
        style={{
          color: theme.headings.h2.color,
          fontSize: theme.headings.h2.size,
          fontFamily: theme.headings.h2.font,
        }}
      >
        <span>{seq}</span>
        <span>{children}</span>
      </h2>
    );
  }
  return children;
};

export const Content = ({ children, printGap }) => {
  const theme = useTheme(ThemeContext);
  return (
    <div
      className={classNames('report-preview-content', {
        ['print-gap-small']: printGap === 'small',
        ['print-gap-medium']: printGap === 'medium',
      })}
      style={{
        color: theme.body.color,
        fontSize: theme.body.size,
        fontFamily: theme.body.font,
      }}
    >
      {children}
    </div>
  );
};

export const Figure = ({ children, seq, caption, length }) => {
  const theme = useTheme(ThemeContext);
  return (
    <figure className="report-preview-figure">
      {length}
      {children}
      <figcaption style={{ color: theme.headings.h1.color }}>
        <strong>Fig {seq}: </strong>
        {caption}
      </figcaption>
    </figure>
  );
};

export const ReportIndicativeRate = ({ indicativeRate, theme }) => {
  return (
    <div className="report-indicative-rate">
      <Progress
        type="circle"
        format={(value) => `${value}%`}
        percent={+indicativeRate.value}
        strokeColor={theme.visuals.divider.color}
      />

      <div className="indicative-rate">Rate (%)</div>
      <div>Country: {indicativeRate.country}</div>

      <div className="indicative-rate-apply">
        <p className="how-to-apply">How to apply the rate</p>
        <p>Turnover &times; Rate = Indicative Sum Insured (up to 12 months)</p>
      </div>
    </div>
  );
};

let pagesOffsetBottoms = {};

const HazardIndexTable = ({
  data,
  className,
  advisorComment,
  irgpChecked,
  userCountryCode,
  indicativeRates,
  reportPageBreakRequired,
  tailoredReportGroupOffsetHeight,
  sectionOffsetHeight,
  harzardIndexTableWrapperRef,
  seq,
  subSeq,
  type,
  riskChecklist = [],
  underwriter,
  ...rest
}) => {
  const dispatch = useDispatch();
  const theme = useTheme(ThemeContext);
  const PRINT_PAGE_HEIGHT = 922;
  const PAGE_BREAK_OFFSET_BOTTOM_THRESHOLD = 220;
  const TABLE_MARGIN_TOP = 24;
  const indicativeRate =
    indicativeRates.length > 0 ? indicativeRates.filter((rate) => rate.countryCode === userCountryCode) : [];
  const harzardIndexTableRef = useRef();

  useEffect(() => {
    if (!harzardIndexTableRef || !harzardIndexTableRef.current) return;

    const checkPageBreak = () => {
      if (!harzardIndexTableWrapperRef) return;
      if (type === 'Tailored Report' && !tailoredReportGroupOffsetHeight) return;

      const harzardIndexTableWrapperOffsetTop = harzardIndexTableWrapperRef?.offsetTop;

      if (type === 'Tailored Report') {
        if (seq === 0 && subSeq === 0) {
          dispatch({
            type: REMOVE_REPORT_PAGE_BREAK_REQUIRED,
          });
        }
        if (subSeq === 0) {
          pagesOffsetBottoms = {};
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (seq === 0) {
          pagesOffsetBottoms = {};
          dispatch({
            type: REMOVE_REPORT_PAGE_BREAK_REQUIRED,
          });
        }
      }

      const accumulatedPagesOffsetBottom =
        Object.values(pagesOffsetBottoms).reduce((acc, offsetBottom) => acc + offsetBottom, 0) || 0;

      let totalPrintOffsetTop;

      if (type === 'Tailored Report') {
        totalPrintOffsetTop =
          harzardIndexTableRef.current.offsetTop +
          accumulatedPagesOffsetBottom +
          tailoredReportGroupOffsetHeight -
          TABLE_MARGIN_TOP;
      } else {
        totalPrintOffsetTop = harzardIndexTableRef.current.offsetTop + accumulatedPagesOffsetBottom - TABLE_MARGIN_TOP;
      }

      const pageOffsetTop = (totalPrintOffsetTop - harzardIndexTableWrapperOffsetTop) % PRINT_PAGE_HEIGHT;

      const currentPageOffsetBottom = PRINT_PAGE_HEIGHT - pageOffsetTop;

      if (currentPageOffsetBottom < PAGE_BREAK_OFFSET_BOTTOM_THRESHOLD) {
        pagesOffsetBottoms = {
          ...pagesOffsetBottoms,
          [data.anchor]: currentPageOffsetBottom,
        };

        dispatch({
          type: SET_REPORT_PAGE_BREAK_REQUIRED,
          section: data.anchor,
        });
      }
    };

    checkPageBreak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sectionOffsetHeight]);

  return (
    <>
      {reportPageBreakRequired.length > 0 && reportPageBreakRequired.includes(data.anchor) && <PageBreak />}

      <table ref={harzardIndexTableRef} className={classNames('report-preview-table', className)} {...rest}>
        {/* <thead style={{ backgroundColor: theme.visuals.divider.color, borderWidth: theme.visuals.divider.width }}>
          <tr>
            <th colSpan={2}>{data.name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2} className="report-preview-table-rating" style={{ color: renderColor(data.rating) }}>
              {`${data.rating}%`}
            </td>
            <td className="report-preview-table-title">Classes of Insurance</td>
          </tr>
          <tr>
            <td>{data.classes.join(', ')}</td>
          </tr>
        </tbody> */}
        <tbody style={{ backgroundColor: theme.visuals.divider.color, borderWidth: theme.visuals.divider.width }}>
          <tr style={{ borderColor: theme.visuals.divider.color, borderWidth: 5, borderStyle: 'solid' }}>
            <td>
              <h2 style={{ color: theme.headings?.pageHeading?.color || '#fff' }}>{data.name}</h2>
              <p style={{ color: theme.headings?.pageHeading?.color || '#fff', marginTop: 5 }}>
                <b>Classes of Insurance : </b>
                {data.classes.join(', ')}
              </p>
            </td>
            <td className="report-preview-table-rating" style={{ color: renderColor(data.rating) }}>
              {data.rating}%
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td colSpan={2} className="report-preview-table-subtitle">
              Risk Summary
            </td>
          </tr>
          <tr className="report-preview-table-list">
            <td colSpan={2} dangerouslySetInnerHTML={{ __html: data.comment }} />
          </tr>
        </tbody>
        {data.name === 'Business Interruption' && irgpChecked && ['au', 'nz'].includes(userCountryCode) && (
          <tbody id="insurable-gross-profit">
            <tr>
              <td colSpan={2} className="report-preview-table-subtitle">
                Indicative Rate of Insurable Gross Profit
              </td>
            </tr>
            <tr className="report-preview-table-list">
              <td colSpan={2}>
                <p>
                  The Indicative Rate of Gross Profit Percentage shown below is provided as a guide to assist you in
                  determining the adequacy of typical Business Interruption sum insured / declared value within a
                  comparable business. One method to test the adequacy of sums insured is to apply the rate to the
                  business’s Annual turnover figure. The result of the calculation will reflect an indicative Insurable
                  Gross Profit value for a 12-month period based on your industry’s average. While this is a useful
                  guide, it should not form the sole basis of determining your sum insured.
                </p>
                {indicativeRate.length > 0 && <ReportIndicativeRate theme={theme} indicativeRate={indicativeRate[0]} />}
                <p>
                  In the event you’re electing to set a maximum Indemnity Period (period of cover) greater than
                  12-months the <em>Insurable Gross Profit</em> value needs to be proportionately increased. That is, a
                  multiplier needs to be applied to the result. For example, if you have chosen an 18-month (1.5 year)
                  indemnity period, multiply the results by 1.5, for 24 months (2 year) multiply by 2 etc. This process
                  only applies to indemnity periods greater than 12-months. In the event you opt to insure for a period
                  of less than 12-months no multiplier is to be applied to the 12-month Insurable Gross Profit value.
                </p>
                <p>
                  <strong>Please note:</strong> The results of the calculation are to be used as a{' '}
                  <strong>
                    <u>guide only</u>
                  </strong>{' '}
                  providing a quick reference for your insurances. The rates have been developed though the averaging of
                  data gained though industry analysis. it is important to note that no two businesses are identical and
                  just as importantly no two insurance policies are the same. As such, we always recommended that a
                  professional analysis and calculation be conducted using your most recent financial statements. Should
                  you wish to learn more about Business Interruption, please visit our free resource available at:{' '}
                  <a
                    href="https://www.biexplained.com"
                    style={{ color: theme.link.color }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.biexplained.com
                  </a>
                  .
                </p>
              </td>
            </tr>
          </tbody>
        )}

        <tbody>
          {advisorComment && (
            <tr>
              <td
                colSpan={2}
                className="report-preview-table-subtitle"
                style={{
                  borderBottomColor: theme.visuals.divider.color,
                  borderWidth: theme.visuals.divider.width,
                }}
              >
                {underwriter ? 'Underwriter Comment' : 'Advisor Comment'}
              </td>
            </tr>
          )}
          {advisorComment && (
            <tr className="advisor-comment-row">
              <td colSpan={2} className="advisor-comment" dangerouslySetInnerHTML={{ __html: advisorComment }} />
            </tr>
          )}
          {riskChecklist.length > 0 && (
            <>
              <tr>
                <td
                  colSpan={2}
                  className="report-preview-table-subtitle"
                  style={{
                    borderBottomColor: theme.visuals.divider.color,
                    borderWidth: theme.visuals.divider.width,
                  }}
                >
                  Risk Checklist
                </td>
              </tr>
              <tr className="advisor-comment-row">
                <td colSpan={2}>
                  <ol>
                    {riskChecklist.map((risk) => (
                      <li className="risk-checklist">
                        <div className="risk-checklist-question">{risk.question}</div>
                        <div className="risk-checklist-answers" dangerouslySetInnerHTML={{ __html: risk.answer }} />
                      </li>
                    ))}
                  </ol>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  irgpChecked: state.report.sections['insurable-gross-profit']?.checked,
  userCountryCode: countryCodeSelector(state),
  indicativeRates: indicativeRateCountryCodeSelector(state),
  reportPageBreakRequired: state.report.reportPageBreakRequired || [],
});

export const Table = connect(mapStateToProps)(HazardIndexTable);

export const List = ({ data = [], ordered = false, renderItem, rowKey }) => {
  const keys = {};

  const renderListItem = (item, index) => {
    if (!renderItem) {
      return null;
    }

    let key;

    if (typeof rowKey === 'function') {
      key = rowKey(item);
    } else if (typeof rowKey === 'string') {
      key = item[rowKey];
    } else {
      ({ key } = item);
    }

    if (!key) {
      key = `list-item-${index}`;
    }

    keys[index] = key;

    return renderItem(item, index);
  };

  const renderList = () => {
    const items = data.map((item, index) => renderListItem(item, index));
    const childrenList = [];
    React.Children.forEach(items, (child, index) => {
      childrenList.push(React.cloneElement(child, { key: keys[index] }));
    });
    return ordered ? (
      <ol className="report-preview-list ordered">{childrenList}</ol>
    ) : (
      <ul className="report-preview-list">{childrenList}</ul>
    );
  };

  return renderList();
};

export const ListItem = ({ children }) => <li>{children}</li>;
