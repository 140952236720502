/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { ReactComponent as LogoDark } from './logo.svg';
import { ReactComponent as LogoColor } from './logo-color.svg';
import { ReactComponent as IProfileLogo } from './iprofile-logo.svg';
import themes from '../../shared/style/themes/themes';

LogoDark.defaultProps = { height: '100%' };
LogoColor.defaultProps = { height: '100%' };
IProfileLogo.defaultProps = { height: '100%' };

export default ({ appTheme, width, height }) => {
  if (appTheme === themes.iprofile) {
    return <IProfileLogo width={width} height={height} />;
  }
  return <LogoColor width={width} height={height} />;
};
