import * as natcatIcons from '../../assets/icons/natcatLayers';
import * as natcatTexts from './components/NatCatTexts/NatCatTexts';

export default {
  fluvialoff: {
    id: 'FL_Fluvial_Official',
    icon: natcatIcons.IconFluvialFlood,
    definition: natcatTexts.FluvialFloodSelectedOfficialZonesDefinition(),
    sources: natcatTexts.FluvialFloodSelectedOfficialZonesSources(),
    zoomMax: 1000,
  },
  fluvialsr: {
    id: 'FL_Fluvial_SwissRe',
    icon: natcatIcons.IconFluvialFlood,
    definition: natcatTexts.PluvialFloodDefinition(),
    sources: natcatTexts.PluvialFloodSources(),
    zoomMax: 32.4,
  },
  pluvial: {
    id: 'FL_Pluvial_SwissRe',
    icon: natcatIcons.IconPluvialFlood,
    definition: natcatTexts.PluvialFloodDefinition(),
    sources: natcatTexts.PluvialFloodSources(),
    zoomMax: 1000,
  },
  stormsurge: {
    id: 'FL_Surge_SwissRe',
    icon: natcatIcons.IconStormSurge,
    definition: natcatTexts.StormSurgeDefinition(),
    sources: natcatTexts.StormSurgeSources(),
    zoomMax: 1000,
  },
  earthquakebedrock: {
    id: 'EQ_Bedrock_Global_SwissRe',
    icon: natcatIcons.IconEarthquake1,
    definition: natcatTexts.EarthquakeBedrockConditionsDefinition(),
    sources: natcatTexts.EarthquakeBedrockConditionsSources(),
    zoomMax: 1000,
  },
  tsunami: {
    id: 'EQ_Tsunami_SwissRe',
    icon: natcatIcons.IconTsunami,
    definition: natcatTexts.TsunamiDefinition(),
    sources: natcatTexts.TsunamiSources(),
    zoomMax: 128,
  },
  windspeed: {
    id: 'WS_Windspeed_Global_SwissRe',
    icon: natcatIcons.IconWindstorm,
    definition: natcatTexts.WindstormDefinition(),
    sources: natcatTexts.WindstormSources(),
    zoomMax: 1000,
  },
  hail: {
    id: 'CS_Hail_Global_SwissRe',
    icon: natcatIcons.IconHailstorm,
    definition: natcatTexts.HailstormDefinition(),
    sources: natcatTexts.HailstormSources(),
    zoomMax: 1000,
  },
  tornado: {
    id: 'CS_Tornado_Global_SwissRe',
    icon: natcatIcons.IconWindstorm,
    definition: natcatTexts.WindstormDefinition(),
    sources: natcatTexts.WindstormSources(),
    zoomMax: 1000,
  },
  landslide: {
    id: 'EQ_Landslide_Global_SwissRe',
    icon: natcatIcons.IconLandslide,
    definition: natcatTexts.LandslideDefinition(),
    sources: natcatTexts.LandslideSources(),
    zoomMax: 1000,
  },
  lightning: {
    id: 'CS_Lightning_Global_SwissRe',
    icon: natcatIcons.IconLightning,
    definition: natcatTexts.LightningDefinition(),
    sources: natcatTexts.LightningSources(),
    zoomMax: 128,
  },
  volcano: {
    id: 'VO_AshThickness_Global_SwissRe',
    icon: natcatIcons.IconVolcano,
    definition: natcatTexts.VolcanoAshTicknessDefinition(),
    sources: natcatTexts.VolcanoAshTicknessSources(),
    zoomMax: 1000,
  },
  bushfire: {
    id: 'WF_Wildfire_Global_SwissRe',
    icon: natcatIcons.IconBushfire,
    definition: natcatTexts.BushfireWildfireDefinition(),
    sources: natcatTexts.BushfireWildfireSources(),
    zoomMax: 128,
  },
  distancetobush: {
    id: 'WF_DistToBush_AUS_SwissRe',
    icon: natcatIcons.IconDistantBush,
    definition: natcatTexts.DistanceToBushDefinition(),
    sources: natcatTexts.DistanceToBushSources(),
    zoomMax: 1000,
  },
  earthquakesoil: {
    id: 'EQ_LocalSoilCondition_Global_SwissRe',
    icon: natcatIcons.IconEarthquake2,
    definition: natcatTexts.EarthquakeLocalSoilConditionsDefinition(),
    sources: natcatTexts.EarthquakeLocalSoilConditionsSources(),
    zoomMax: 1000,
  },
  distancetocoast: {
    id: 'GEO_DistToCoast_Global_SwissRe',
    icon: natcatIcons.IconDistanceCoast,
    definition: natcatTexts.DistanceToCoastDefinition(),
    sources: natcatTexts.DistanceToBushSources(),
    zoomMax: 1000,
  },
};
