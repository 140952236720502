import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { CountryContext } from '../../contexts';
import { countrySelector, themeSelector } from '../../scenes/Account/AccountSelectors';
import Theme from '../Theme/Theme';
import themes from '../../shared/style/themes/themes';
import Spinner from '../Spinner/Spinner';
import { initialize } from './AppActions';
import { useSiteRedirect } from './AppHooks';

const App = ({ children, initialized, initialize, country }) => {
  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (themeSelector() === themes.iprofile) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = '/iprofilerisks-favicon.png';
    }
  }, []);

  useSiteRedirect();

  return initialized ? (
    <CountryContext.Provider value={{ country }}>
      {themeSelector() === themes.iprofile && (
        <Helmet>
          <title>{themeSelector() === themes.iprofile ? 'IProfileRisk' : 'LMI RiskCoach'} </title>
        </Helmet>
      )}

      <Theme>{children}</Theme>
    </CountryContext.Provider>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = (state) => ({
  initialized: state.app.initialized,
  country: countrySelector(state),
});

const mapDispatchToProps = { initialize };

export default connect(mapStateToProps, mapDispatchToProps)(App);
