import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { fetchNatCatMap } from '../../NatCatProfileActions';

const NatCatMap = ({ layerId, zoom = 4, loading }) => {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.natcat?.natcatMaps?.[layerId]);

  useEffect(() => {
    if (!map) {
      dispatch(fetchNatCatMap(layerId, zoom));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  if (!map && loading) {
    return <Spin spinning={loading} tip="Loading map.." />;
  }
  return map ? (
    <Spin spinning={loading} tip="Updating map..">
      <img src={map} alt={`${layerId}-Map`} />
    </Spin>
  ) : null;
};

export default NatCatMap;
