import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';

const Item = ({ children, item, checked, extra, size, onCheck, disabled }) => (
  <li role="menuitem" className={classNames('report-list-item', size, { disabled })}>
    <div
      role="menuitem"
      tabIndex={0}
      onKeyDown={(e) => e.preventDefault()}
      className="report-list-item-meta"
      onClick={(e) => {
        e.stopPropagation();
        const target = document.getElementById(item.anchor);
        if (target) {
          target.scrollIntoView();
        }
      }}
    >
      {item.icon && size !== 'small' && <Icon component={item.icon} size={40} className="report-list-item-meta-icon" />}
      <div className="report-list-item-meta-content">
        <h3>{item.title || item.name}</h3>
        {item.description && <div>{item.description}</div>}
      </div>
      {extra || (
        <>
          {item.onEdit && (
            <Link to={item.onEdit}>
              <Button icon="edit" type="link" />
            </Link>
          )}
          {<Checkbox value={item.id} checked={checked} disabled={disabled || item.required} onChange={onCheck} />}
        </>
      )}
    </div>
    {children}
  </li>
);

export default Item;
