import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Page } from '../../../../../../../../components/Print/Print';
import './IProfileCover.scss';

import iProfileLogo from './assets/iprofile-logo.png';
import poweredByLogo from './assets/lmi-logo.png';
import steadfastLogo from './assets/steadfast-logo.svg';

const IProfileCover = ({ theme, insuredBusinessName, industryName, businessLocation, date, dateFormat, type }) => {
  return (
    <Page>
      <div className="iprofile-report-preview-cover">
        <div className="overlay" />
        <div
          className="iprofile-report-preview-cover-header"
          style={{ fontFamily: theme.headings.h2.font, backgroundColor: theme.covers[0].bgColor[0] }}
        >
          <div className="company-logo pop" />
          <h2 className="insured-business-name pop">{insuredBusinessName}</h2>
          <h1 className="title pop">
            {type === 'Natural Catastrophe Report'
              ? 'Natural Catastrophe Report'
              : businessLocation?.address
              ? 'Risk Hazard and Natural Catastrophe Report'
              : 'Insurance Risk Report'}
          </h1>
          <h2 className="industry pop" style={{ fontFamily: theme.headings.h2.font }}>
            {industryName}
          </h2>
          <div className="address pop" style={{ fontFamily: theme.body.font }}>
            {businessLocation?.address || ' '}
          </div>
          <div className="date-issued pop" style={{ fontFamily: theme.body.font }}>
            {`Date issued:  ${moment(date).format('DD MMMM YYYY')}`}
          </div>
        </div>
        <div
          className={classNames('iprofile-report-preview-cover-body', {
            natcat: type === 'Natural Catastrophe Report',
          })}
        ></div>
        <div className="iprofile-report-preview-cover-footer">
          <img src={poweredByLogo} alt="lmi-logo" className="logo" />
          <img src={steadfastLogo} alt="steadfast-logo" className="logo" />
        </div>
      </div>
    </Page>
  );
};

export default IProfileCover;
