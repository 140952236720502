import { COLOR_RATING_HIGH, COLOR_RATING_LOW, COLOR_RATING_MEDIUM } from '../Industry/utils/renderColor';
import natcatData from './natcatData';

export const renderGradeColour = (grade) => {
  if (grade?.toLowerCase() === 'low') {
    return COLOR_RATING_LOW;
  }
  if (grade?.toLowerCase() === 'medium') {
    return COLOR_RATING_MEDIUM;
  }
  if (grade?.toLowerCase() === 'high') {
    return COLOR_RATING_HIGH;
  }
  return '#e9e9e9';
};

export const getNatCatRiskGradeColour = (grade, legends) => {
  const found = legends.rules.find((rule) => rule?.title?.toLowerCase() === grade?.toLowerCase());
  if (found) {
    return found?.symbolizers[0]?.raster.color || found?.symbolizers[0]?.polygon.fill;
  }
  return '#000000';
};

export const findNatCatProfileText = (id) => {
  return natcatData.find((profileData) => profileData.id === id);
};
