import React from 'react';
import { Article, DynamicPage, PageBreak } from '../../../../components/Print/Print';
import Cover from './components/Cover/Cover';
import Disclaimer from './components/Disclaimer/Disclaimer';
import UserProfile from './components/UserProfile/UserProfile';
import CompanyProfile from './components/CompanyProfile/CompanyProfile';
import General from './components/General/General';
import BackCover from './components/BackCover/BackCover';
import HazardAssessment from './components/HazardAssessment/HazardAssessment';
import SignificantExposure from './components/SignificantExposure/SignificantExposure';
import RiskChecklist from './components/RiskChecklist/RiskChecklist';
import NatCatDescription from './components/NatCatDescription/NatCatDescription';
import './ReportPreview.scss';

export const FrontMatter = () => (
  <>
    <Cover />
    <Disclaimer />
  </>
);

export const Main = () => {
  let figureSeq = 0;
  // eslint-disable-next-line no-return-assign
  return (
    <>
      <DynamicPage>
        <UserProfile />
        <CompanyProfile />
        <PageBreak />
        <General />
        <PageBreak />
        <HazardAssessment figureSeq={(figureSeq += 1)} />
        <SignificantExposure />
        <NatCatDescription />
        <RiskChecklist />
      </DynamicPage>
      <BackCover />
    </>
  );
};

export default () => {
  return (
    <Article className="report-preview">
      <FrontMatter />
      <Main />
    </Article>
  );
};
