/* eslint-disable no-case-declarations */
import {
  REQUEST_NATCAT_PROFILES,
  RECEIVE_NATCAT_PROFILES,
  REQUEST_NATCAT_MAP,
  RECEIVE_NATCAT_MAP,
  RESET_NATCAT_MAP,
  SET_BUSINESS_LOCATION,
} from './NatCatProfileActions';

const initialState = {
  loadingNatCatProfiles: false,
  loadingNatCatMap: [],
  natcatProfiles: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_NATCAT_PROFILES:
      return { ...state, loadingNatCatProfiles: true };
    case RECEIVE_NATCAT_PROFILES:
      return {
        ...state,
        loadingNatCatProfiles: false,
        natcatProfiles: action?.natcatProfiles
          .sort((a, b) => {
            const nameA = a?.name;
            const nameB = b?.name;
            return nameA < nameB ? -1 : 1;
          })
          .sort((a, b) => {
            const desA = a?.analysis?.risk_description.toLowerCase();
            const desB = b?.analysis?.risk_description.toLowerCase();
            if (!desA) return 1;
            if (!desB) return -1;
            if (desA === desB) return 0;
            return desA < desB ? 1 : -1;
          }),
      };
    case REQUEST_NATCAT_MAP:
      return { ...state, loadingNatCatMap: [...state.loadingNatCatMap, action.loadingNatCatMap] };
    case RESET_NATCAT_MAP:
      return {
        ...state,
        natcatMaps: {},
      };
    case RECEIVE_NATCAT_MAP:
      return {
        ...state,
        loadingNatCatMap: state.loadingNatCatMap.filter((mapId) => mapId !== action.natcatMap?.id),
        natcatMaps: { ...state.natcatMaps, [action.natcatMap.id]: action.natcatMap.map },
      };
    case SET_BUSINESS_LOCATION:
      return {
        ...state,
        businessLocation: action.location,
      };
    default:
      return state;
  }
};
