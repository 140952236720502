/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userAppSiteSelector } from '../../scenes/Account/AccountSelectors';

const IPROFILE_SITE = process.env.REACT_APP_IPROFILE_DOMAIN;
const DEFAULT_SITE = process.env.REACT_APP_DOMAIN;

const appSite = {
  default: 'lmiriskcoach',
  iprofile: 'iprofilerisk',
};

export const useSiteRedirect = () => {
  const userSite = useSelector(userAppSiteSelector);
  const token = localStorage.getItem('token');

  const redirectUrlGenerator = (domain) => {
    return `https://${domain}${window.location.pathname}?token=${token}`;
  };

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      if (userSite === appSite.iprofile && !window.location.host.includes(appSite.iprofile)) {
        window.location.replace(redirectUrlGenerator(IPROFILE_SITE));
      }
    } else {
      if (userSite === appSite.iprofile && !window.location.hostt.toLowerCase().includes(appSite.iprofile)) {
        window.location.replace(redirectUrlGenerator(IPROFILE_SITE));
      }
      if (userSite === appSite.default && !window.location.host.toLowerCase().includes(appSite.default)) {
        window.location.replace(redirectUrlGenerator(DEFAULT_SITE));
      }
    }
  }, [userSite]);

  return null;
};
