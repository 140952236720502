import React from 'react';
import { Input, Menu, Dropdown } from 'antd';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import _, { add } from 'lodash';

class AddressSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: props.initialValue };
  }

  handleChange = (address) => {
    if (!address) {
      const { onChange } = this.props;
      onChange(address);
    }
    this.setState({ address });
  };

  getAddressComponent = (addressComponents, field) => {
    const result = _.find(addressComponents, (item) => _.includes(item.types, field));
    return result ? result.short_name : '';
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        console.log(address, results);
        const { onChange } = this.props;
        const street = address.split(',')[0];
        const addressComponents = results[0].address_components;
        const suburb = this.getAddressComponent(addressComponents, 'locality');
        const postcode = this.getAddressComponent(addressComponents, 'postal_code');
        const state = this.getAddressComponent(addressComponents, 'administrative_area_level_1');
        const country = this.getAddressComponent(addressComponents, 'country');
        onChange({ street, suburb, postcode, state, country });
        this.setState({ address: street });
      })
      .catch((error) => console.error('Error', error));
  };

  render() {
    const { placeholder, prefix } = this.props;
    const { address } = this.state;
    return (
      <PlacesAutocomplete value={address} onChange={this.handleChange} onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const menu = (
            <Menu>
              {suggestions.map((suggestion) => (
                <Menu.Item key={suggestion.placeId}>
                  <div {...getSuggestionItemProps(suggestion)}>
                    <span>{suggestion.description}</span>
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          );

          return (
            <div>
              <Dropdown overlay={menu} visible={suggestions.length !== 0}>
                <Input
                  {...getInputProps({
                    placeholder,
                    className: 'location-search-input',
                    prefix,
                    type: 'search',
                  })}
                />
              </Dropdown>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default AddressSearch;
