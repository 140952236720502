/* eslint-disable no-unused-vars */
import adLmiClaims from './ad-lmi-claims.jpg';
import adLmiCollege from './ad-lmi-college.jpg';
import adLmiRiskCoachOtg from './ad-lmi-riskcoach-otg.jpg';
import adWebinar from './ad-webinar.jpg';
import adTyk from './ad-tyk.jpg';
import adSteadfast from './ad-steadfast.png';

export default [
  {
    key: 'lmi-tyk',
    title: 'Test Your Knowledge',
    description: 'New Feature on RiskCoach!!',
    linkType: 'local',
    link: '/test-your-knowledge',
    cover: adTyk,
    countries: ['au', 'nz'],
    scope: 'lmi',
  },
  {
    key: 'lmi-webinar',
    title: 'Webinar Schedule',
    description: 'Free webinar registration! We want to keep you informed of our upcoming webinar training! ',
    link: 'https://lmigroup.io/webinars',
    cover: adWebinar,
    countries: ['au', 'nz'],
    scope: 'lmi',
  },
  {
    key: 'lmi-claims',
    title: 'LMI Claims Preparation',
    description: 'The best kept secret in Insurance',
    link: 'https://www.lmigroup.io/services/claims',
    cover: adLmiClaims,
    countries: ['au', 'nz'],
    scope: 'lmi',
  },
  {
    key: 'lmi-education',
    title: 'LMI College',
    description: 'Quality accredited technical Insurance training solutions',
    link: 'https://lmigroup.io/introducing-lmi-college-of-insurance-and-risk/',
    cover: adLmiCollege,
    countries: ['au', 'nz'],
    scope: 'lmi',
  },
  {
    key: 'lmi-riskcoach-otg',
    title: 'RiskCoach OnTheGo',
    description: 'RiskCoach OnTheGo V2 out now!',
    link: 'https://lmigroup.io/eservices/riskcoach/#Portable',
    cover: adLmiRiskCoachOtg,
    button_text: 'Download the App',
    countries: ['au', 'nz', 'gb'],
    scope: 'lmi',
  },
  {
    key: 'steadfast-ad',
    title: 'Find a steadfast broker',
    link: 'https://www.steadfast.com.au/find-an-insurance-broker/',
    cover: adSteadfast,
    button_text: 'Find a broker',
    countries: ['au', 'nz', 'gb'],
    scope: 'iprofile',
  },
];
