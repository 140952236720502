import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { industryNameSelector } from '../../../../../Industry/IndustrySelectors';
import { businessLocationSelector, insuredBusinessNameSelector } from '../../../../ReportSelectors';
import {
  companyLogoSelector,
  dateFormatSelector,
  reportThemeSelector,
  isUnderwriterSelector,
  themeSelector,
} from '../../../../../Account/AccountSelectors';
import { ThemeContext, TypeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import Templates from './Templates';
import themes from '../../../../../../shared/style/themes/themes';

const Cover = ({ reportTheme, ...props }) => {
  const type = useContext(TypeContext);
  const theme = useTheme(ThemeContext);
  const appTheme = themeSelector();
  const { DefaultCover, MarshCover, AonCover, IProfileCover } = Templates;

  if (appTheme === themes.iprofile) {
    return <IProfileCover theme={theme} {...props} type={type} />;
  }
  if (reportTheme.label === 'Marsh theme') {
    return <MarshCover theme={theme} {...props} />;
  }
  if (reportTheme.label === 'AON theme') {
    return <AonCover theme={theme} {...props} />;
  }
  return <DefaultCover theme={theme} type={type} {...props} />;
};

const mapStateToProps = (state) => ({
  companyLogo: companyLogoSelector(state),
  reportTheme: reportThemeSelector(state),
  industryName: industryNameSelector(state),
  insuredBusinessName: insuredBusinessNameSelector(state),
  businessLocation: businessLocationSelector(state),
  dateFormat: dateFormatSelector(state),
  underwriter: isUnderwriterSelector(state),
});

export default connect(mapStateToProps)(Cover);
