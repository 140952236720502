import React from 'react';
import classNames from 'classnames';
import { Progress } from 'antd';

import './RiskGradeStatusBar.scss';

const RiskGradeStatusBar = ({ maxLength, length, colour = 'orange', strokeWidth = 15, className }) => {
  return (
    <Progress
      className={classNames('risk-grade-bar', className)}
      percent={(length / maxLength) * 100}
      strokeWidth={strokeWidth}
      strokeColor={{
        '0%': colour,
        '100%': colour,
      }}
      showInfo={false}
    />
  );
};

export default RiskGradeStatusBar;
