import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Button, Avatar, Icon, Dropdown } from 'antd';
import classNames from 'classnames';
import Logo from '../Logo/Logo';
import Modal from '../Modal/Modal';
import Login from '../Login/Login';
import PointBadge from '../../scenes/Point/components/Badge/Badge';
import AccountDropdown from '../../scenes/Account/components/Dropdown/Dropdown';
import { personNameSelector, userAvatarSelector } from '../../scenes/Account/AccountSelectors';
import './Header.scss';

const AntHeader = Layout.Header;

const Header = ({ isHome, personName, userAvatar, theme }) => {
  const [visible, setVisible] = useState(true);
  const [shadow, setShadow] = useState(false);
  const [transparent, setTransparent] = useState(isHome);

  const loggedIn = localStorage.getItem('token');

  useEffect(() => {
    let oldScrollTop = 0;
    const onScroll = () => {
      const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
      setVisible(!(scrollTop >= oldScrollTop && scrollTop > 200));
      setShadow(scrollTop > 10);
      setTransparent(scrollTop < window.innerHeight - 78);
      oldScrollTop = scrollTop;
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <AntHeader className={classNames('header', { visible, shadow, transparent: isHome && transparent })}>
      <Link to="/" className="header-brand">
        <Logo appTheme={theme} />
      </Link>
      {loggedIn ? (
        <>
          <div className="header-badge">
            <PointBadge />
          </div>
          <Dropdown overlay={<AccountDropdown />} placement="bottomRight" trigger={['click']}>
            <div className="header-user">
              <Avatar shape="circle" size="large" src={userAvatar} className="header-avatar">
                {`${personName.charAt(0).toUpperCase()}`}
              </Avatar>
              <div className="header-name">{personName}</div>
              <Icon type="more" className="header-icon" />
            </div>
          </Dropdown>
        </>
      ) : (
        <Modal
          toggle={(show) => (
            <Button size="large" ghost onClick={show} className="header-login-btn">
              Log in
            </Button>
          )}
        >
          {() => <Login />}
        </Modal>
      )}
    </AntHeader>
  );
};

const mapStateToProps = (state) => ({
  personName: personNameSelector(state),
  userAvatar: userAvatarSelector(state),
});

export default connect(mapStateToProps)(Header);
