/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Autocomplete } from '@react-google-maps/api';
import Button from '../../../../components/Button/Button';
import { setBusinessLocation, fetchNatCatProfiles } from '../../NatCatProfileActions';

import './AddressSearch.scss';

const AddressSearch = ({ onAfterChange, showSearch, onSearch, text = false }) => {
  const autocompleteRef = useRef();
  const [searchable, setSearchable] = useState(false);
  const dispatch = useDispatch();
  const loadingNatCatProfiles = useSelector((state) => state.natcat.loadingNatCatProfiles);
  const location = useSelector((state) => state.natcat?.businessLocation);
  const profiles = useSelector((state) => state.natcat?.natcatProfiles);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const fetchNatCat = () => dispatch(fetchNatCatProfiles());
  const handleOnPlacesChanged = async () => {
    const address = autocompleteRef.current.getPlace();
    if (!address) return;
    const { geometry, formatted_address } = address;
    const location = {
      address: formatted_address,
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    };
    dispatch(setBusinessLocation(location));
    setSearchable(true);
    if (!onSearch) {
      await fetchNatCat();
    }
    if (onAfterChange) {
      await onAfterChange();
    }
  };

  return (
    <Spin spinning={loadingNatCatProfiles} tip="Loading natcat profiles..">
      {text && (
        <>
          <h3 className="drawer-content-title">Location (NatCat)</h3>
          {location && (
            <>
              <h4>Currently selected location : </h4>
              <p className="selected-location">{location.address}</p>
            </>
          )}
          <p>
            Enter your location to visualise your natural hazard exposure. iProfileRisk NatCat data is sourced from
            SwissRe, providing 16 NatCat summaries for a specified location. To maintain integrity, we merely pass on
            this information without change.
          </p>
        </>
      )}

      <div className="report-address-search">
        <Autocomplete onLoad={onLoad} onPlaceChanged={handleOnPlacesChanged} className="autocomplete">
          <input
            disabled={loadingNatCatProfiles}
            className="address-search"
            type="text"
            placeholder={location?.address && profiles ? 'Change Location' : 'Start typing the address'}
          />
        </Autocomplete>
        {showSearch && (
          <Button
            type="primary"
            className="address-search-button"
            onClick={onSearch || fetchNatCat}
            disabled={!searchable}
            loading={loadingNatCatProfiles}
          >
            {location?.address && profiles ? 'Change Location' : 'Search'}
          </Button>
        )}
      </div>
    </Spin>
  );
};

export default AddressSearch;
