/* eslint-disable max-len */
import { createSelector } from 'reselect';
import _ from 'lodash';
import { riskChecklistNavItemsSelector } from '../Document/DocumentSelectors';
import {
  hazardIndexSelector,
  significantExposuresSelector,
  naturalCatastropheSelector,
  industryIdSelector,
} from '../Industry/IndustrySelectors';
import {
  userProfileSelector,
  userAvatarSelector,
  companyInfoSelector,
  companyNameSelector,
  reportThemeSelector,
} from '../Account/AccountSelectors';

export const sectionsSelector = (state) => state.report.sections;
export const loadingCustomizationsSelector = (state) => state.report.loadingCustomizations;

/**
 * This selector returns an object containing the sections in the quick report
 * the children is a list that must have {id, anchor}
 * section may be omitted if it does not have children
 */
export const quickReportSectionsSelector = createSelector(
  riskChecklistNavItemsSelector,
  hazardIndexSelector,
  (riskChecklistNavItems, hazardIndex = []) => ({
    'report-hazard-assessment': {
      children: _.map(hazardIndex, (item) => ({ ...item, anchor: `report-hazard-assessment_${item.slug}` })),
    },
    'report-risk-checklist': {
      children: _.map(riskChecklistNavItems, (item) => ({ ...item, anchor: `report-risk-checklist_${item.slug}` })),
    },
  })
);

export const insuredBusinessNameSelector = createSelector(
  sectionsSelector,
  (sections) => sections?.cover?.insuredBusinessName || 'Insured Business Name'
);

export const businessLocationSelector = (state) => state.natcat.businessLocation;

export const reportUserProfileSelector = createSelector(
  sectionsSelector,
  userProfileSelector,
  userAvatarSelector,
  companyNameSelector,
  (sections, userProfile, userAvatar, companyName) =>
    sections && sections['user-profile']?.checked
      ? { ...userProfile, avatar: userAvatar, company: companyName }
      : undefined
);

export const reportCompanyProfileSelector = createSelector(
  sectionsSelector,
  companyInfoSelector,
  (sections, companyInfo) => (sections && sections['company-profile']?.checked ? companyInfo : undefined)
);

export const reportHazardIndexSelector = createSelector(
  sectionsSelector,
  hazardIndexSelector,
  (sections, hazardIndex) => {
    if (sections && sections['report-hazard-assessment'] && hazardIndex) {
      if (sections['report-hazard-assessment'].checked && sections['report-hazard-assessment'].children === undefined) {
        return hazardIndex.map((item) => ({ ...item, anchor: `report-hazard-assessment_${item.slug}` }));
      }
      return hazardIndex
        .filter((item) =>
          _.includes(
            sections['report-hazard-assessment'].children?.map((id) => `${id}`),
            `${item.id}`
          )
        )
        .map((item) => ({ ...item, anchor: `report-hazard-assessment_${item.slug}` }));
    }
    return undefined;
  }
);

export const reportSignificantExposuresSelector = createSelector(
  sectionsSelector,
  significantExposuresSelector,
  (sections, significantExposureList) =>
    sections && sections['report-significant-exposures']?.checked ? significantExposureList : undefined
);

export const reportNaturalCatastopheDescriptionSelector = createSelector(
  sectionsSelector,
  naturalCatastropheSelector,
  (sections, naturalCatastropheDescription) =>
    sections && sections['report-natural-catastrophe']?.checked ? naturalCatastropheDescription : undefined
);

export const reportRiskChecklistSelector = createSelector(
  sectionsSelector,
  (state) => state.industry.riskChecklist,
  (sections, riskChecklist) => {
    if (sections && sections['report-risk-checklist'] && riskChecklist) {
      const raw = _.chain(riskChecklist)
        .map((item) => ({
          id: item.id,
          name: item.title,
          insuranceClass: item.insurance_class.name,
          insuranceClassId: `${item.insurance_class.id}`,
          slug: item.insurance_class.slug,
        }))
        .groupBy('insuranceClass')
        .map((list, insuranceClass) => ({
          id: list[0].insuranceClassId,
          name: insuranceClass,
          anchor: `report-risk-checklist_${list[0].slug}`,
          items: list,
        }));

      if (sections['report-risk-checklist'].checked && sections['report-risk-checklist'].children === undefined) {
        return raw.value();
      }
      return raw
        .filter((item) =>
          _.includes(
            sections['report-risk-checklist'].children?.map((id) => `${id}`),
            `${item.id}`
          )
        )
        .value();
    }
    return undefined;
  }
);

const defaultFont = "Arial, 'Helvetica Neue', Helvetica, sans-serif";
export const reportDownloadParamsSelector = createSelector(
  reportThemeSelector,
  insuredBusinessNameSelector,
  (reportTheme, insuredBusinessName) => ({
    format: 'A4',
    displayHeaderFooter: true,
    margin: { top: '120px', bottom: '80px' },
    headerTemplate: `
      <div
        style="
          width:300px;
          margin-left:auto;
          padding:10px 60px;
          text-align:right;
          font-size:10px;
          line-height:1.5;
          border-bottom:1px solid ${reportTheme.value.colorBar || reportTheme.value.visuals.divider.color};"
      >
        <div style="color:${
          reportTheme.value.colorTitle || reportTheme.value.headings.h1.color
        };font-weight:400; font-family: ${defaultFont};">INSURANCE RISK REPORT</div>
        <div style="color:${
          reportTheme.value.colorTitle || reportTheme.value.headings.h1.color
        };font-weight:700;text-transform:uppercase; font-family: ${defaultFont};">${insuredBusinessName}</div>
      </div>`,
    footerTemplate: `
      <div
        class="pageNumber"
        style="
          -webkit-print-color-adjust:exact;
          width:54px;
          height:16px;
          margin:0 0 0 auto;
          padding:0;
          line-height:16px;
          font-size:10px;
          text-align:center;
          color:#fff;
          font-family: ${defaultFont};
          background-color:${reportTheme.value.colorBar || reportTheme.value.visuals.divider.color};" 
      />`,
  })
);

export const reportCustomizationSelector = createSelector(industryIdSelector);

/**
 * Select hazard index section of a report customization
 */
export const hazardIndexCustomizationSelector = createSelector(
  hazardIndexSelector,
  (state) => state.report.customization,
  (hazardIndexes, customization) => {
    if (hazardIndexes) {
      const defaultCustomization = {
        grouping: [
          {
            id: 'recommended',
            classes: hazardIndexes ? hazardIndexes.map(({ id }) => `${id}`) : [],
            seq: 0,
          },
          {
            id: 'for_review',
            classes: [],
            seq: 1,
          },
          {
            id: 'for_consideration',
            classes: [],
            seq: 2,
          },
        ],
        comments: [],
      };

      if (customization.isPristine) return { ...customization, ...defaultCustomization };

      const hazardIndexCustomization = customization.customizations?.find((s) => s.section === 'hazard_index');
      if (hazardIndexCustomization) {
        const parsed = JSON.parse(hazardIndexCustomization.customization);

        if (_.isEmpty(parsed.grouping)) {
          return { ...customization, ...defaultCustomization };
        }

        return {
          ...customization,
          grouping: parsed.grouping
            .sort((a, b) => a.seq - b.seq)
            .map((item) => ({
              id: item.id,
              classes: item.classes.map(String),
              seq: item.seq,
            })),
          comments: parsed.comments,
        };
      }
    }

    return undefined;
  }
);

export const underwriterHazardIndexCustomizationSelector = createSelector(
  hazardIndexSelector,
  (state) => state.report.customization,
  (hazardIndexes, underwriterCustomization) => {
    if (hazardIndexes) {
      if (underwriterCustomization.isPristine) return { ...underwriterCustomization, customizations: [] };
      const hazardIndexCustomization = underwriterCustomization.customizations?.find(
        (s) => s.section === 'hazard_index_underwriter'
      );

      const parsed = hazardIndexCustomization ? JSON.parse(hazardIndexCustomization.customization) : [];
      if (_.isEmpty(parsed)) {
        return { ...underwriterCustomization, customizations: [] };
      }

      return {
        ...underwriterCustomization,
        customizations: parsed.classes,
      };
    }

    return undefined;
  }
);
