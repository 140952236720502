/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Slider, Checkbox, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNatCatMap } from '../../../NatCatProfile/NatCatProfileActions';
import natcatData from '../../../NatCatProfile/natcatData';

import './NatCatMaps.scss';

const NatCatMaps = ({ item, onGroupCheck, disabled }) => {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.natcat?.natcatProfiles);
  const mapLoading = useSelector((state) => state.natcat?.loadingNatCatMap);
  const checkedList = item.checkedChildren || [];

  useEffect(() => {
    console.log('call');
    setTimeout(() => {
      onGroupCheck(
        profiles?.map((p) => p.id),
        item.id
      );
    }, 100);
  }, [profiles]);

  const onZoomChange = (value, id) => {
    dispatch(fetchNatCatMap(id, value));
  };

  const onChange = (e) => {
    if (checkedList.includes(e.target.value)) {
      onGroupCheck(
        checkedList.filter((l) => l !== e.target.value),
        item.id,
        false
      );
    } else {
      onGroupCheck([...checkedList, e.target.value], item.id, false);
    }
  };

  return (
    <div className="nat-cat-maps">
      <Divider />
      <h3>NatCat Maps</h3>
      {profiles &&
        profiles?.map(({ id, name, legends }) => (
          <div className="profile" key={id}>
            <div
              role="menuitem"
              className="profile-control"
              onClick={() => {
                const target = document.getElementById(id);
                if (target) {
                  target.scrollIntoView();
                }
              }}
            >
              {name}
              <Checkbox
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={id}
                checked={checkedList.includes(id)}
                onChange={onChange}
              />
            </div>

            <Slider
              className="zoom-slider"
              disabled={disabled || mapLoading.includes(id)}
              defaultValue={4}
              step={0.2}
              min={0.2}
              max={natcatData?.[id]?.zoomMax + 0.1 || 1000.1}
              onAfterChange={(value) => {
                onZoomChange(value, legends?.layerId);
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default NatCatMaps;
