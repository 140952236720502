import React from 'react';
import Item from '../Item/Item';
import Groups from '../Groups/Groups';
import NatCatMaps from '../NatCatMaps/NatCatMaps';

export default ({ list = [], onCheck, onGroupCheck }) => {
  return (
    <div className="report-list">
      <ul>
        {list.map((item) => {
          const checked = list
            .filter((item) => item.checked)
            .map((item) => item.id)
            .includes(item.id);
          if (item?.children?.length) {
            return <Groups key={item.id} item={item} onCheck={onCheck} onGroupCheck={onGroupCheck} />;
          }
          if (item?.id === 'report-natural-catastrophe') {
            return (
              <Item
                key={item.id}
                item={item}
                checked={checked}
                onCheck={(e) => onCheck(e.target.checked, item.id)}
                disabled={item.disabled}
              >
                {checked && <NatCatMaps item={item} onGroupCheck={onGroupCheck} disabled={item.disabled} />}
              </Item>
            );
          }
          return (
            <Item key={item.id} item={item} checked={checked} onCheck={(e) => onCheck(e.target.checked, item.id)} />
          );
        })}
      </ul>
    </div>
  );
};
