/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Collapse, Slider, Icon as AntIcon } from 'antd';
import Layout from '../../components/Layout/Layout';
import AddressSearch from './components/AddressSearch/AddressSearch';
import RiskGradeStatusBar from './components/RiskGradeStatusBar/RiskGradeStatusBar';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import NatCatMap from './components/NatCatMap/NatCatMap';
import { fetchNatCatProfiles, fetchNatCatMap } from './NatCatProfileActions';
import { renderGradeColour } from './utils';
import natcatData from './natcatData';
import * as icons from '../../assets/icons/IndustryFeature';
import './NatCatProfile.scss';

const { Panel } = Collapse;

const ProfileHeader = ({ id, name, legends, analysis, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="natcat-profile-header" onClick={onClick(id)}>
      <div className="natcat-profile-header-inner title">
        <Icon
          component={natcatData?.[id]?.icon}
          size={36}
          className="natcat-profile-icon"
          style={{ color: '#fff', fontSize: 22, backgroundColor: renderGradeColour(analysis?.risk_description) }}
        />{' '}
        {name}
      </div>
      <div className="natcat-profile-header-inner grade">
        <RiskGradeStatusBar
          className="status-bar"
          strokeWidth={22}
          maxLength={legends?.rules?.length || 0}
          length={analysis?.risk_index || 0}
          colour={renderGradeColour(analysis?.risk_description)}
        />{' '}
        <span className="grade-description">{analysis?.risk_description || 'No risk data'}</span>
      </div>
    </div>
  );
};

const ProfileResult = ({
  layerId,
  classifications,
  legends = [],
  analysis = [],
  loadingMap,
  zoomLevel,
  onZoomChange,
}) => (
  <div className="results">
    <div className="results-container">
      <h3>Result : {analysis?.hazard}</h3>
      <div className="legends-subtitle">{legends?.subtitle}</div>
      <h3>Definition : </h3>
      <div>{natcatData?.[layerId]?.definition}</div>
      <div className="map">
        <NatCatMap layerId={legends?.layerId} zoom={zoomLevel} loading={loadingMap} />
      </div>
      <h3>Sources : </h3>
      <div className="sources">{natcatData?.[layerId]?.sources}</div>
    </div>
    <div className="legends-container">
      <h5>Risk Grades:</h5>
      <div className="legend-tiles">
        {legends.rules
          .filter((r) => r.title !== 'No Data')
          .map((rule) => (
            <div className="legend-tile" key={rule.title}>
              <div
                className="legend-fill"
                style={{ backgroundColor: rule.symbolizers[0]?.raster?.color || rule.symbolizers[0]?.polygon?.fill }}
              />
              <div className="legend-title">{rule.title}</div>
            </div>
          ))}

        <div className="risk-classification">
          <div>
            Low risks: <br />
            {classifications?.risk_categories?.low_risks.map((risk, i) => (
              <span key={risk.item} className={classNames('risk-item', { first: i === 0 })}>
                {risk.item}
              </span>
            ))}{' '}
          </div>
          <div>
            Medium risks: <br />
            {classifications?.risk_categories?.medium_risks.map((risk, i) => (
              <span key={risk.item} className={classNames('risk-item', { first: i === 0 })}>
                {risk.item}
              </span>
            ))}
          </div>
          <div>
            High risks: <br />
            {classifications?.risk_categories?.high_risks.map((risk, i) => (
              <span key={risk.item} className={classNames('risk-item', { first: i === 0 })}>
                {risk.item}
              </span>
            ))}
          </div>
        </div>
      </div>
      <h4>Zoom Level: {zoomLevel}</h4>
      <Slider
        className="zoom-slider"
        disabled={loadingMap}
        defaultValue={zoomLevel}
        step={0.2}
        min={0.2}
        max={natcatData?.[layerId]?.zoomMax || 1000.1}
        onAfterChange={(value) => {
          if (onZoomChange) onZoomChange(value, legends?.layerId);
        }}
      />
    </div>
  </div>
);

const NatCatProfile = ({
  natcatProfiles,
  natcatMaps,
  loadingNatCatMap,
  businessLocation,
  withLayout = false,
  showReport,
}) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const [zoomLevel, setZoomLevel] = useState({});
  const dispatch = useDispatch();
  const onSearch = () => {
    dispatch(fetchNatCatProfiles());
  };

  const onZoomChange = (value, id) => {
    dispatch(fetchNatCatMap(id, value));
    setZoomLevel({ ...zoomLevel, [id]: value });
  };

  const togglePanel = (key) => {
    return (event) => {
      if (activeKeys.includes(key)) {
        setActiveKeys(activeKeys.filter((activeKey) => activeKey !== key));
      } else {
        setActiveKeys([...activeKeys, key]);
      }
      event.preventDefault();
    };
  };

  const handleExpandAll = () => {
    const active = natcatProfiles.map((p) => p.id);
    setActiveKeys(active);
  };

  const handeCloseAll = () => {
    setActiveKeys([]);
  };

  const renderNatCatProfiles = () => (
    <div className="component-wrapper natcat-profiles">
      <h1 className="control">
        NatCat Profiles{' '}
        {natcatProfiles?.length && showReport && (
          <Button type="primary" size="large" onClick={showReport}>
            <AntIcon component={icons.IconReport} /> View Report
          </Button>
        )}
      </h1>

      <div className="location-search-container">
        <AddressSearch showSearch onSearch={onSearch} text />
      </div>

      {natcatProfiles ? (
        <>
          <div className="control">
            <div>{businessLocation?.address && <h2 className="address">{businessLocation?.address}</h2>}</div>
            {activeKeys.length === natcatProfiles.length ? (
              <Button type="text" onClick={handeCloseAll}>
                Close All
              </Button>
            ) : (
              <Button type="text" onClick={handleExpandAll}>
                Expand All
              </Button>
            )}
          </div>
          <Collapse bordered={false} activeKey={activeKeys} className="natcat-layer">
            {natcatProfiles.map(({ id, name, legends, analysis, risk_classifications }) => (
              <Panel
                showArrow={false}
                className="natcat-layer-panel"
                header={
                  <ProfileHeader id={id} name={name} legends={legends} analysis={analysis} onClick={togglePanel} />
                }
                key={id}
              >
                <ProfileResult
                  layerId={id}
                  legends={legends}
                  analysis={analysis}
                  zoomLevel={zoomLevel?.[legends?.layerId] || 4}
                  map={natcatMaps?.[legends?.layerId]}
                  loadingMap={loadingNatCatMap.includes(legends?.layerId)}
                  onZoomChange={onZoomChange}
                  classifications={risk_classifications}
                />
              </Panel>
            ))}
          </Collapse>
        </>
      ) : (
        <p className="paragraph">Please search location to view Natural Catastrophe analysis details</p>
      )}
    </div>
  );
  return withLayout ? (
    <Layout showMenu showSearch>
      {renderNatCatProfiles()}
    </Layout>
  ) : (
    renderNatCatProfiles()
  );
};

const mapStateToProps = (state) => ({
  businessLocation: state.natcat.businessLocation,
  natcatProfiles: state.natcat.natcatProfiles,
  natcatMaps: state.natcat.natcatMaps,
  loadingNatCatProfiles: state.natcat.loadingNatCatProfiles,
  loadingNatCatMap: state.natcat.loadingNatCatMap,
});

export default connect(mapStateToProps)(NatCatProfile);
