/* eslint-disable consistent-return */
import axios from 'axios';
import { message } from 'antd';
import { getBase64 } from '../Account/utils';

export const REQUEST_NATCAT_PROFILES = 'natcat/REQUEST_NATCAT_PROFILES';
export const RECEIVE_NATCAT_PROFILES = 'natcat/RECEIVE_NATCAT_PROFILES';
export const REQUEST_NATCAT_MAP = 'natcat/REQUEST_NATCAT_MAP';
export const RECEIVE_NATCAT_MAP = 'natcat/RECEIVE_NATCAT_MAP';
export const RESET_NATCAT_MAP = 'natcat/RESET_NATCAT_MAP';
export const SET_BUSINESS_LOCATION = 'natcat/SET_BUSINESS_LOCATION';

const API_BASE = process.env.REACT_APP_API_BASE;

export const setBusinessLocation = (location) => {
  return { type: SET_BUSINESS_LOCATION, location };
};

const requestNatCatProfiles = () => ({
  type: REQUEST_NATCAT_PROFILES,
});

const receiveNatCatProfiles = (natcatProfiles) => ({
  type: RECEIVE_NATCAT_PROFILES,
  natcatProfiles,
});

const requestNatCatMap = (id) => ({
  type: REQUEST_NATCAT_MAP,
  loadingNatCatMap: id,
});

const receiveNatCatMap = (natcatMap) => ({
  type: RECEIVE_NATCAT_MAP,
  natcatMap,
});

const resetNatCatMap = () => ({
  type: RESET_NATCAT_MAP,
});

export const fetchNatCatProfiles = () => async (dispatch, getState) => {
  dispatch(requestNatCatProfiles());
  const url = `${API_BASE}catnet/layers`;
  try {
    const location = getState().natcat?.businessLocation;
    const params = { longitude: 32.997052 || location?.lng, latitude: 34.652676 || location?.lat };
    const res = await axios.get(url, { params });
    dispatch(receiveNatCatProfiles(res.data));
    dispatch(resetNatCatMap());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveNatCatProfiles());
    message.error('Failed to fetch natcat profiles');
  }
};

export const fetchNatCatMap = (LayerId, zoom) => async (dispatch, getState) => {
  try {
    dispatch(requestNatCatMap(LayerId));
    const url = `${API_BASE}catnet/map`;
    const location = getState().natcat?.businessLocation;
    const params = {
      LayerId,
      Longitude: location?.lng,
      Latitude: location?.lat,
      Zoom: zoom * 0.5,
    };
    const res = await axios.get(url, { params, responseType: 'blob' });
    const natcatMap = await getBase64(res.data);
    dispatch(receiveNatCatMap({ map: natcatMap, id: LayerId }));
  } catch (err) {
    dispatch(receiveNatCatMap({ id: LayerId }));
  }
};
