import React, { useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider, connect } from 'react-redux';
import { Layout, Spin, message, Icon } from 'antd';
import store from '../../../../store';
import Button from '../../../../components/Button/Button';
import Download from '../../../../components/Download/Download';
import ReportPreview, { Main, FrontMatter } from '../../scenes/ReportPreview/ReportPreview';
import Drawer from '../Drawer/Drawer';
import CheckList from '../CheckList/CheckList';
import { download } from '../../../../components/App/AppActions';
import { updateUserSettings } from '../../../Account/AccountActions';
import { themesSelector, reportThemeSelector, loadingUserSettingsSelector } from '../../../Account/AccountSelectors';
import { reportDownloadParamsSelector } from '../../ReportSelectors';
import { ThemeContext, TypeContext } from '../../ReportContext';
import './Report.scss';

const { Content, Header } = Layout;

const Report = ({
  fullscreen,
  setFullscreen,
  themes,
  reportTheme,
  reportDownloadParams,
  loadingTheme,
  loaded,
  natcatLoaded,
  download,
  updateUserSettings,
  handleBack,
  type,
  underwriter,
}) => {
  const [editing, setEditing] = useState(window.innerWidth > 768);

  const toggleFullscreen = () => {
    if (fullscreen) {
      window.scroll(0, 0);
    } else {
      document.getElementById('report-content').scrollIntoView();
      setEditing(false);
    }
    setFullscreen(!fullscreen);
  };

  const checkDisabled = (item) => {
    if (item === 'saveButton') {
      if (type === 'Natural Catastrophe Report') {
        return !natcatLoaded;
      }
      return !loaded;
    }

    if (item === 'fullscreenButton') {
      if (type === 'Natural Catastrophe Report') {
        return false;
      }
    }

    return true;
  };

  const save = () => {
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <ThemeContext.Provider value={reportTheme.value}>
          <TypeContext.Provider value={type}>
            <Main />
          </TypeContext.Provider>
        </ThemeContext.Provider>
      </Provider>
    );

    const cover = renderToStaticMarkup(
      <Provider store={store}>
        <ThemeContext.Provider value={reportTheme.value}>
          <FrontMatter />
        </ThemeContext.Provider>
      </Provider>
    );

    return download(content, cover, reportDownloadParams);
  };

  const updateReportTheme = async (value) => {
    try {
      const res = await updateUserSettings({ report_theme: value });
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Content id="report-content">
      <Header className="report-header">
        <h3>{type}</h3>
        <div>
          {handleBack && (
            <Button secondary className="btn-step" onClick={handleBack}>
              <Icon type="left" />
              Back
            </Button>
          )}

          <Button
            secondary
            icon={fullscreen ? 'fullscreen-exit' : 'fullscreen'}
            className="btn-full-screen"
            onClick={toggleFullscreen}
          >
            {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          </Button>

          <Download
            download={save}
            fileName={underwriter ? 'Risk Consideration Report.pdf' : 'Insurance Risk Report.pdf'}
          >
            {(onDownload, downloading) => (
              <Button
                secondary
                type="primary"
                icon="download"
                loading={downloading}
                disabled={checkDisabled('saveButton')}
                onClick={onDownload}
              >
                Save
              </Button>
            )}
          </Download>
        </div>
      </Header>
      <main className="report-main">
        <Drawer visible={editing} setVisible={setEditing}>
          <TypeContext.Provider value={type}>
            <CheckList
              themes={themes}
              selectedTheme={reportTheme}
              setTheme={updateReportTheme}
              underwriter={underwriter}
            />
          </TypeContext.Provider>
        </Drawer>
        <div className="report-main-preview">
          <ThemeContext.Provider value={reportTheme.value}>
            <TypeContext.Provider value={type}>
              <Spin spinning={loadingTheme}>
                <ReportPreview />
              </Spin>
            </TypeContext.Provider>
          </ThemeContext.Provider>
        </div>
      </main>
    </Content>
  );
};

const mapStateToProps = (state) => ({
  themes: themesSelector(state),
  reportTheme: reportThemeSelector(state),
  reportDownloadParams: reportDownloadParamsSelector(state),
  loadingTheme: loadingUserSettingsSelector(state),
  loaded: !!state.report.hazardIndexChartDataUrl || !!state.report.hazardIndexGroupChartDataUrl,
  natcatLoaded: !!state.natcat.natcatProfiles,
});

const mapDispatchToProps = { download, updateUserSettings };

export default connect(mapStateToProps, mapDispatchToProps)(Report);
