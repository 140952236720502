import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect, withRouter, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sider from '../../components/Sider/Sider';
import QuickReport from './scenes/QuickReport/QuickReport';
import TailoredReport from './scenes/TailoredReport/TailoredReport';
// eslint-disable-next-line no-unused-vars
import UnderwriterReport from './scenes/UnderwriterReport/UnderwriterReport';
import UnderwriterUnderConstruction from './scenes/UnderwriterReport/UnderwriterUnderConstruction';
import { fetchHazardIndex } from '../Industry/IndustryActions';
import { countryCodeSelector, isUnderwriterSelector } from '../Account/AccountSelectors';
import { IconQuickReport, IconReportBuilder } from './assets/icons';
import './Report.scss';
import { clearReportCustomization } from './ReportActions';
import IntroTailoredReport from './components/IntroTailoredReport/IntroTailoredReport';

const Report = ({ match, location }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('quick-report');
  const history = useHistory();
  const { id: industryId } = useParams();
  const dispatch = useDispatch();
  const country = useSelector(countryCodeSelector);
  const underwriter = useSelector(isUnderwriterSelector);

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
    history.push(`${match.url}/${e.key}`);
  };

  useEffect(() => {
    const lastPath = location.pathname.split('/').pop();
    setSelectedMenu(lastPath);
  }, [setSelectedMenu, location.pathname]);

  useEffect(() => {
    dispatch(fetchHazardIndex(industryId, country));
  }, [country, dispatch, industryId]);

  return (
    <Layout className="report">
      {!fullscreen && (
        <Sider
          collapsible
          title="Report"
          className="report-sider"
          menuItems={
            !underwriter
              ? [
                  { id: 'quick-report', name: 'Quick Report', icon: IconQuickReport },
                  {
                    id: 'tailored-report',
                    name: 'Tailored Report',
                    icon: IconReportBuilder,
                    action: <IntroTailoredReport icon={IconReportBuilder} onButtonClick={handleMenuClick} />,
                  },
                ]
              : [{ id: 'underwriter-report', name: 'Underwriter report', icon: IconQuickReport }]
          }
          selectedMenuItem={selectedMenu}
          onMenuClick={handleMenuClick}
        />
      )}
      <Switch>
        <Route
          exact
          path={match.url}
          render={() => {
            dispatch(clearReportCustomization());
            return <Redirect to={`${match.url}/${underwriter ? 'underwriter-report' : 'quick-report'}`} />;
          }}
        />
        <Route
          path="/industries/:id/report/quick-report"
          render={
            !underwriter
              ? () => <QuickReport fullscreen={fullscreen} setFullscreen={setFullscreen} />
              : () => <UnderwriterUnderConstruction />
          }
        />
        <Route
          path="/industries/:id/report/tailored-report"
          render={
            !underwriter
              ? () => <TailoredReport fullscreen={fullscreen} setFullscreen={setFullscreen} />
              : () => <UnderwriterUnderConstruction />
          }
        />
        <Route
          path="/industries/:id/report/underwriter-report"
          render={
            !underwriter
              ? () => <QuickReport fullscreen={fullscreen} setFullscreen={setFullscreen} />
              : () => <UnderwriterUnderConstruction />
          }
        />
      </Switch>
    </Layout>
  );
};

export default withRouter(Report);
