import React from 'react';
import { useSelector } from 'react-redux';
import DisclamerTemplates from './Templates';
import './Disclaimer.scss';
import { reportThemeSelector, themeSelector } from '../../../../../Account/AccountSelectors';
import themes from '../../../../../../shared/style/themes/themes';

export default () => {
  const { Default, AonDisclaimer, IProfileDisclaimer } = DisclamerTemplates;
  const reportTheme = useSelector(reportThemeSelector);
  const appTheme = themeSelector();

  if (appTheme === themes.iprofile) {
    return <IProfileDisclaimer />;
  }
  if (reportTheme?.label === 'AON theme') {
    return <AonDisclaimer />;
  }

  return <Default />;
};
