import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import Button from '../../../../components/Button/Button';
import './Ad.scss';
import covers from './assets';

export default ({ country, scope = 'lmi' }) => (
  <div className="card">
    <Carousel effect="fade" autoplay autoplaySpeed={5000} dots={false}>
      {covers
        .filter((item) => item.scope === scope)
        .map(
          (item) =>
            item.countries.includes(country) && (
              <div key={item.key} className="dashboard-ad">
                <div className="dashboard-ad-cover" style={{ backgroundImage: `url(${item.cover})` }} />
                <div className="dashboard-ad-overlay">
                  <h1>{item.title}</h1>
                  <h3>{item.description}</h3>
                  {item?.linkType && item.linkType === 'local' ? (
                    <Link to={item.link}>
                      <Button secondary block>
                        {item.button_text || 'Find out more'}
                      </Button>
                    </Link>
                  ) : (
                    <Button secondary block href={item.link} target="_blank">
                      {item.button_text || 'Find out more'}
                    </Button>
                  )}
                </div>
              </div>
            )
        )}
    </Carousel>
  </div>
);
