import React, { useState } from 'react';

import Layout from '../../components/Layout/Layout';
import Report from '../Report/components/Report/Report';
import NatCatProfile from './NatCatProfile';

const NatCatReport = () => {
  const [fullscreen, setFullscreen] = useState(false);
  const [showReport, setShowReport] = useState(false);

  return (
    <Layout showMenu showSearch>
      {showReport ? (
        <Report fullscreen={fullscreen} setFullscreen={setFullscreen} type="Natural Catastrophe Report" />
      ) : (
        <NatCatProfile showReport={() => setShowReport(!showReport)} />
      )}
    </Layout>
  );
};

export default NatCatReport;
