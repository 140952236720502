import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import AddressSearch from '../../../NatCatProfile/components/AddressSearch/AddressSearch';
import { IconBushfire, IconLandslide, IconStormSurge, IconHailstorm } from '../../../../assets/icons/natcatLayers';
import Button from '../../../../components/Button/Button';
import './NatCat.scss';

const NatCat = ({ natcat }) => (
  <div className="dashboard-natcat card">
    <h2>Natural Catastrophe</h2>
    <div className="dashboard-natcat-icons">
      <Icon component={IconBushfire} />
      <Icon component={IconLandslide} />
      <Icon component={IconStormSurge} />
      <Icon component={IconHailstorm} />
    </div>
    <p>Enter your location to generate Natural Catastrophe analysis.</p>
    <AddressSearch />
    <Button
      className="dashboard-natcat-search-btn"
      type="primary"
      disabled={!natcat.natcatProfiles?.length}
      loading={natcat.loadingNatCatProfiles}
    >
      <Link to="/natcat">View NatCat Profiles</Link>
    </Button>
  </div>
);

export default NatCat;
