import React from 'react';
import { Button } from 'antd';
import { Page } from '../../../../../../../../components/Print/Print';
import iprofileLogo from './assets/iprofile-logo.png';
import steadfastLogo from './assets/steadfast-white-logo.png';
import './IProfileBackCover.scss';

const STEADFAST_WEB = 'www.steadfast.com.au';
const STEADFAST_ENQUIRY_EMAIL = 'enquiries@steadfastriskgroup.com.au';

export default ({ theme, appTheme }) => {
  return (
    <Page>
      <div className="iprofile-report-preview-back-cover">
        <div
          className="iprofile-report-preview-back-cover-info"
          style={{ backgroundColor: theme.covers[0].bgColor[0] }}
        >
          <div>
            <Button type="link" href={`https://${STEADFAST_WEB}`} target="_blank">
              {STEADFAST_WEB}
            </Button>
          </div>
          <div>
            <Button type="link" href={`mailto:${STEADFAST_ENQUIRY_EMAIL}`}>
              {STEADFAST_ENQUIRY_EMAIL}
            </Button>
          </div>
          <div className="logos-container">
            <img className="logo" src={steadfastLogo} alt="steadfast logo" />
            <img className="logo" src={iprofileLogo} alt="iprofile logo" />
          </div>
        </div>
      </div>
    </Page>
  );
};
