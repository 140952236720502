/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Heading, Content } from '../ReportComponent/ReportComponent';
import Icon from '../../../../../../components/Icon/Icon';
import RiskGradeStatusBar from '../../../../../NatCatProfile/components/RiskGradeStatusBar/RiskGradeStatusBar';
import { sectionsSelector } from '../../../../ReportSelectors';
import { themeSelector } from '../../../../../Account/AccountSelectors';
import themes from '../../../../../../shared/style/themes/themes';
import { ThemeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import { getNatCatRiskGradeColour, renderGradeColour } from '../../../../../NatCatProfile/utils';
import NatCatMap from '../../../../../NatCatProfile/components/NatCatMap/NatCatMap';
import natcatData from '../../../../../NatCatProfile/natcatData';

import './NatCatDescription.scss';

const Results = ({ legends = [], analysis = [], showMap, classifications, loadingMap }) => (
  <>
    <div className="results-container">
      <h3>
        Result : <span style={{ color: getNatCatRiskGradeColour(analysis?.hazard, legends) }}>{analysis?.hazard}</span>
      </h3>
      <div className="legends-subtitle">{legends?.subtitle}</div>
      <RiskGradeStatusBar
        maxLength={legends?.rules?.length || 0}
        length={analysis?.risk_index || 0}
        colour={getNatCatRiskGradeColour(analysis?.hazard, legends)}
      />
      {showMap && (
        <div className="map">
          <NatCatMap layerId={legends?.layerId} loading={loadingMap} />
        </div>
      )}
    </div>
    <div className="legends">
      <h5>Risk Grades:</h5>
      <div className="legend-tiles">
        {legends.rules
          .filter((r) => r.title !== 'No Data')
          .map((rule) => (
            <div className="legend-tile" key={rule.title}>
              <div
                className="legend-fill"
                style={{
                  backgroundColor: rule.symbolizers?.[0]?.raster?.color || rule.symbolizers?.[0]?.polygon?.fill,
                }}
              />
              <div className="legend-title">{rule.title}</div>
            </div>
          ))}
        <div className="risk-classification">
          <div>
            Low risks: <br />
            {classifications?.risk_categories?.low_risks.map((risk, i) => (
              <span key={risk.item} className={classNames('risk-item', { first: i === 0 })}>
                {risk.item}
              </span>
            ))}{' '}
          </div>
          <div>
            Medium risks: <br />
            {classifications?.risk_categories?.medium_risks.map((risk, i) => (
              <span key={risk.item} className={classNames('risk-item', { first: i === 0 })}>
                {risk.item}
              </span>
            ))}
          </div>
          <div>
            High risks: <br />
            {classifications?.risk_categories?.high_risks.map((risk, i) => (
              <span key={risk.item} className={classNames('risk-item', { first: i === 0 })}>
                {risk.item}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
);

const NatCatDescription = ({ sections, appTheme, natcatProfiles, loadingNatCatMap }) => {
  const theme = useTheme(ThemeContext);

  return (
    <>
      {sections &&
        sections['report-natural-catastrophe'] &&
        sections['report-natural-catastrophe'].checked &&
        appTheme === themes.iprofile && (
          <div className="report-preview-no-break">
            <Heading seq={sections['report-natural-catastrophe'].seq} anchor="report-natural-catastrophe">
              Natural Catastrophe Descriptions
            </Heading>
            <Content printGap="medium">
              {natcatProfiles &&
                natcatProfiles.map(({ id, name, map, analysis, risk_classifications, legends, sources }) => (
                  <table
                    id={id}
                    key={id}
                    className={classNames('report-preview-table', 'report-natural-catastrophe-table')}
                  >
                    <tbody>
                      <tr className="profile-name">
                        <td>
                          <h2 style={{ color: theme.covers[0]?.bgColor[0] }}>
                            <span className="title">
                              <Icon
                                component={natcatData?.[id]?.icon}
                                size={36}
                                className="report-natcat-icon"
                                style={{ backgroundColor: renderGradeColour(analysis?.risk_description), fontSize: 24 }}
                              />{' '}
                              {name}
                            </span>
                            <span
                              className="risk-grade"
                              style={{
                                color:
                                  renderGradeColour(analysis?.risk_description) ||
                                  getNatCatRiskGradeColour(analysis?.risk_description, legends),
                              }}
                            >
                              {analysis?.risk_description || 'No risk data'}
                            </span>
                          </h2>
                        </td>
                      </tr>
                      <tr className="definition">
                        <td>
                          <h3>Definition</h3>
                          {natcatData?.[id]?.definition}
                        </td>
                      </tr>
                      <tr>
                        <td className="results">
                          <Results
                            layerName={name}
                            legends={legends}
                            analysis={analysis}
                            sources={sources}
                            classifications={risk_classifications}
                            map={map}
                            showMap={
                              sections['report-natural-catastrophe']?.children &&
                              sections['report-natural-catastrophe'].children.includes(id)
                            }
                            loadingMap={loadingNatCatMap.includes(legends?.layerId)}
                          />
                        </td>
                      </tr>
                      <tr className="sources">
                        <td>
                          <h3>Sources : </h3>
                          {natcatData?.[id] && natcatData[id].sources}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
            </Content>
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  appTheme: themeSelector(state),
  natcatProfiles: state.natcat.natcatProfiles,
  loadingNatCatProfiles: state.natcat.loadingNatCatProfiles,
  loadingNatCatMap: state.natcat.loadingNatCatMap,
});

export default connect(mapStateToProps)(NatCatDescription);
