import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import Layout from '../../components/Layout/Layout';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import Navigation from './components/Navigation/Navigation';
import Report from '../Report/Report';
import AdvancedSearch from './scenes/AdvancedSearch/AdvancedSearch';
import HazardIndex from './scenes/HazardIndex/HazardIndex';
import SignificantExposure from './scenes/SignificantExposure/SignificantExposure';
import RiskChecklist from './scenes/RiskChecklist/RiskChecklist';
import MaterialEquipment from './scenes/MaterialEquipment/MaterialEquipment';
import RiskAssessment from './scenes/RiskAssessment/RiskAssessment';
import RelatedIndustry from './scenes/RelatedIndustry/RelatedIndustry';
import IndustryCode from './scenes/IndustryCode/IndustryCode';
import Endorsement from './scenes/Endorsement/Endorsement';
import Media from './scenes/Media/Media';
import TestYourKnowledge from './scenes/TestYourKnowledge/TestYourKnowledge';
import NatCatProfile from '../NatCatProfile/NatCatProfile';
import { fetchIndustry } from './IndustryActions';
import { industryIdSelector, hasMarketSelector, industryQuizzesSelector } from './IndustrySelectors';
import { countryCodeSelector, themeSelector } from '../Account/AccountSelectors';
import * as icons from '../../assets/icons/IndustryFeature';
import { CountryContext } from '../../contexts';
import useHashFragment from './hooks/useHashFragment';
import themes from '../../shared/style/themes/themes';

const routes = Object.freeze([
  {
    route: 'report',
    show: true,
    title: 'Report',
    component: <Report />,
    icon: icons.IconReport,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'hazard-index',
    show: true,
    title: 'Hazard Index',
    component: <HazardIndex />,
    icon: icons.IconHazardIndex,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'natcat-profile',
    title: 'NatCat',
    show: themeSelector() === themes.iprofile,
    component: <NatCatProfile />,
    icon: icons.IconNaturalDisasters,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'significant-exposures',
    show: true,
    title: 'Significant Exposures',
    component: <SignificantExposure />,
    icon: icons.IconSignificantExposures,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'risk-checklist',
    show: true,
    title: 'Risk Checklist',
    component: <RiskChecklist />,
    icon: icons.IconRiskChecklist,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'materials-equipments',
    show: true,
    title: 'Materials & Equipments',
    component: <MaterialEquipment />,
    icon: icons.IconMaterialsEquipments,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'risk-assessment',
    show: true,
    title: 'Risk & Assessment',
    component: <RiskAssessment />,
    icon: icons.IconRiskDescription,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'related-industries',
    show: true,
    title: 'Related Industries',
    component: <RelatedIndustry />,
    icon: icons.IconRelatedIndustries,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'codes',
    show: true,
    title: 'Industry Codes',
    component: <IndustryCode />,
    icon: icons.IconIndustryCodes,
    countries: ['au', 'nz', 'gb'],
  },
  {
    route: 'endorsements',
    show: true,
    title: 'Endorsement List',
    component: <Endorsement />,
    icon: icons.IconEndorsements,
    countries: ['au', 'nz'],
  },
  { route: 'media', show: true, title: 'Media', component: <Media />, icon: icons.IconMedia, countries: ['au', 'nz'] },
]);

const getMarketToken = () => {
  const linkgenToken = localStorage.getItem('linkgen_token');
  if (linkgenToken) {
    return `linkgen=${linkgenToken}`;
  }
  const token = localStorage.getItem('token');
  return `tok=${token}`;
};

const Industry = ({ match, location, industryId, hasMarket, fetchIndustry, industryQuizzes, userCountry }) => {
  const [hasQuiz, setHasQuiz] = useState(false);

  useHashFragment();

  useEffect(() => {
    if (match.params.id) {
      fetchIndustry(match.params.id);
    }
  }, [match.params.id, fetchIndustry]);

  useEffect(() => {
    if (industryQuizzes) {
      if (industryQuizzes.length === 0) {
        setHasQuiz(false);
      } else {
        industryQuizzes.forEach((quiz) => {
          if (quiz.country.toLowerCase() === userCountry.toLowerCase()) {
            setHasQuiz(true);
          }
        });
      }
    }
  }, [industryQuizzes, userCountry, setHasQuiz]);

  const extraNavItems = [
    {
      show: hasQuiz,
      route: 'quiz',
      title: 'Test Your Knowledge',
      component: <TestYourKnowledge />,
      icon: icons.IconNotes,
      countries: ['au', 'nz'],
    },
    {
      show: hasMarket,
      title: 'Market',
      icon: icons.IconMarket,
      url: `${process.env.REACT_APP_API_THE_MARKET}results?industryId=${industryId}&${getMarketToken()}`,
      countries: ['au'],
    },
  ];

  // Navigation bar only cares about top level routes.
  const segments = location.pathname.split('/');
  const topLevelRoutes = [...routes, ...extraNavItems].map((r) => r.route);
  const selectedKey = _.findLast(segments, (s) => _.some(topLevelRoutes, (r) => s === r));

  return (
    <CountryContext.Consumer>
      {({ country }) => (
        <Layout showMenu>
          <Switch>
            <Route path="/industries/browse" component={AdvancedSearch} />
            <>
              <Navigation
                items={[
                  ...routes.filter((r) => r.show && r.countries.includes(country)),
                  ...extraNavItems.filter((item) => item.show && item.countries.includes(country)),
                ]}
                selectedKey={selectedKey}
              />
              <Switch>
                {/* <Route exact path={match.url} render={() => <Redirect to={`${match.url}/hazard-index`} />} />
            {[...routes, ...extraNavItems].map((item) => {
              if (item.component) {
                const componentToRender = React.cloneElement(item.component);
                return (
                  <Route
                    key={item.route}
                    path={`${match.url}/${item.route}`}
                    render={() => <ErrorBoundary>{componentToRender}</ErrorBoundary>}
                  />
                );
              }
              return null;
            })} */}
                <Route exact path={match.url} render={() => <Redirect to={`${match.url}/hazard-index`} />} />
                {[...routes, ...extraNavItems].map((item) => {
                  if (item.component) {
                    const componentToRender = React.cloneElement(item.component);
                    return (
                      <Route
                        key={item.route}
                        path={`/industries/:id/${item.route}`}
                        render={() => <ErrorBoundary>{componentToRender}</ErrorBoundary>}
                      />
                    );
                  }
                  return null;
                })}
                <Redirect to="/not-found" />
              </Switch>
            </>
          </Switch>
        </Layout>
      )}
    </CountryContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  hasMarket: hasMarketSelector(state),
  industryQuizzes: industryQuizzesSelector(state),
  userCountry: countryCodeSelector(state),
});

const mapDispatchToProps = { fetchIndustry };

export default connect(mapStateToProps, mapDispatchToProps)(Industry);
