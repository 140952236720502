import React from 'react';
import { ThemeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import { themeSelector } from '../../../../../Account/AccountSelectors';
import appThemes from '../../../../../../shared/style/themes/themes';

import BackCover from './templates/DefaultBackCover/BackCover';
import IProfileBackCover from './templates/IProfileBackCover/IProfileBackCover';

export default () => {
  const theme = useTheme(ThemeContext);
  if (themeSelector() === appThemes.iprofile) {
    return <IProfileBackCover theme={theme} appTheme={appThemes.iprofile} />;
  }

  return <BackCover theme={theme} />;
};
