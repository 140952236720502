import React from 'react';

import './NatCatTexts.scss';

export const BushfireWildfireDefinition = () => (
  <>
    The Wildfire Map shows the likelihood for the occurrence of wildfires in a certain area, depending on the intrinsic
    characteristics of the region. The layer resolution is 300m at the equator. The measure of land susceptibility to
    fire for this model is based on historic fire frequency per unit area (2001-2019), trend in climate change as a
    proxy for fire danger levels (2001-2020) and wildland-urban interface (WUI). Burned area and fire danger levels
    integrate event frequency, while WUI focus on the variable of interest from a damage perspective. Property in the
    wildland-urban interface (WUI), or regions adjacent to or within undeveloped natural areas, is particularly more
    susceptible to wildfire hazard given the proximity to vegetative fuels and the adopted set of predisposing factors.
  </>
);

export const BushfireWildfireSources = () => (
  <ul>
    <li>
      MODIS MCD64CMQ Climate Modeling Grid Burned Area Product (
      <a
        href="https://modis-fire.umd.edu/files/MODIS_C6_BA_User_Guide_1.3.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        MCD64A1 User&apos;s Guide
      </a>
      (umd.edu). Accessed from University of Maryland fuoco SFTP (formerly FTP) server.
    </li>
    <li>
      Daily Fire Weather Index (FWI) data (
      <a href="https://effis.jrc.ec.europa.eu/about-effis/data-license" target="_blank" rel="noopener noreferrer">
        https://effis.jrc.ec.europa.eu/about-effis/data-license
      </a>
      ). Accessed from Copernicus Climate Change Data Store (
      <a href="https://cds.climate.copernicus.eu/cdsapp#!/home" target="_blank" rel="noopener noreferrer">
        https://cds.climate.copernicus.eu/cdsapp#!/home
      </a>
      ).
    </li>
    <li>
      ESA-CCI Land cover v2.1.1 Epoch 2019 (
      <a
        href="https://cds.climate.copernicus.eu/api/v2/terms/static/satellite-land-cover.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://cds.climate.copernicus.eu/api/v2/terms/static/satellite-land-cover.pdf
      </a>
      ). Accessed from Copernicus Climate Change Service (
      <a
        href="https://cds.climate.copernicus.eu/cdsapp#!/dataset/satellite-land-cover?tab=form"
        target="_blank"
        rel="noopener noreferrer"
      >
        Land cover classification gridded maps from 1992 to present derived from satellite observations (copernicus.eu)
      </a>
      )
    </li>
  </ul>
);

export const DistanceToBushDefinition = () => (
  <>
    Distance calculated from vegetation density. The objective is to highlight the significance of &apos;proximity to
    bush&apos; in case of widespread fires. The resolution of the layer is 25m.
  </>
);

export const DistanceToBushSources = () => <>DEA Land Cover (Landsat) 25m</>;

export const DistanceToCoastDefinition = () => (
  <>
    The distance to coast service provides information as advertised on the distance of any given location on land to
    the nearest coast
  </>
);

export const DistanceToCoastSources = () => <>Swiss Re</>;

export const EarthquakeBedrockConditionsDefinition = () => (
  <>
    The earthquake – or seismic hazard - layer is a global map of Peak Ground Acceleration (PGA) in units of g for a
    return period of 475 years at 1-kilometer spatial resolution for reference site (bedrock) condition. Additional
    information is available in Modified Mercalli Intensity (MMI). The data is provided by the Global Earthquake Model
    (GEM) Global Seismic Hazard Map (version 2018.1).
  </>
);

export const EarthquakeBedrockConditionsSources = () => (
  <ul>
    <li> Global Earthquake Model (GEM) Global Seismic Hazard Map (version 2018.1) </li>
  </ul>
);

export const EarthquakeLocalSoilConditionsDefinition = () => (
  <>
    The &quot;Seismic Hazard on Local Soil&quot; is a layer that shows expected ground motion level at actual local site
    conditions.
  </>
);

export const EarthquakeLocalSoilConditionsSources = () => (
  <ul>
    <li> Global Earthquake Model (GEM) Hazard Model Mosaic (Pagani et al.,2018) </li>
    <li>
      United States Geological Survey (USGS) Shear Wave Velocity in the upper 30m (Vs30) from slope (Heath et al., 2020)
    </li>
  </ul>
);

export const FluvialFloodDefinition = () => (
  <>
    Swiss Re Global Flood Zones™ (SR GFZ) are calculated based on Swiss Re&apos;s proprietary and patented multiple
    regression approach (Geomorph). They describe naturally flooded areas affected on average every 50, 100, 200 and 500
    years. Only river sections with catchment areas larger than 50 km² were taken into account. For smaller rivers the
    Swiss Re Pluvial Flood Zones should be looked up. The effect of flood protection measures is not taken into account
    which typically means an overestimation of the risk for locations in a 100y zone in urban areas
  </>
);

export const FluvialFloodSources = () => (
  <ul>
    <li> Intermap&apos;s NEXTMap World 30 digital elevation model on 30m resolution. </li>
    <li> SRTM digital surface model (NASA, USA, approx. 90m resolution). </li>
    <li>
      Jean-Francois Pekel, Andrew Cottam, Noel Gorelick, Alan S. Belward, High-resolution mapping of global surface
      water and its long-term changes. Nature 540, 418-422 (2016). (doi:10.1038/nature20584).
    </li>
  </ul>
);

export const LandslideDefinition = () => (
  <>
    The global landslide layer reflects both the landslide susceptibility and landslide runout risk. As a result, the
    likelihood of terrain failure, the propagation of risk down slope and deposition areas of possible landslides are
    depicted in the layer, whereby primarily earthquake-induced landslide processes are considered. In this model, the
    term &apos;landslide&apos; refers to mass movement processes including rockfall, debris flow sand mud
    slides(Varnes1978). While the visualization provides information on the overall landslide risk, the risk lookups
    enable the user to get details on the underlying susceptibility and runout hazard values. The layer has global
    coverage (upto +59.9°N) at 1 second of arc of resolution (~30m at the equator).
  </>
);

export const LandslideSources = () => (
  <div id="sources-grid" style={{ display: 'grid' }}>
    <div style={{ gridRow: 1, gridColumn: 1 / 3 }}>
      <b>Data Set</b>
    </div>
    <div style={{ gridRow: 1, gridColumn: 3 }}>
      <b>Description</b>
    </div>
    <div style={{ gridRow: 1, gridColumn: 4 }}>
      <b>Vintage</b>
    </div>
    <div style={{ gridRow: 1, gridColumn: 5 }}>
      <b>Source</b>
    </div>
    <div style={{ gridRow: 2 / 4, gridColumn: 1 }}> Global Landslide Inventory </div>
    <div style={{ gridRow: 2, gridColumn: 2 }}> Global Disastrous Landslides </div>
    <div style={{ gridRow: 2, gridColumn: 3 }}> Landslide data collected by NASA </div>
    <div style={{ gridRow: 2, gridColumn: 4 }}> 2007 and younger</div>
    <div style={{ gridRow: 2, gridColumn: 5 }}>
      <a
        href="https://maps.nccs.nasa.gov/arcgis/apps/MapAndAppGallery/index.html?appid=574f26408683485799d02e857e5d9521"
        target="_blank"
        rel="noopener noreferrer"
      >
        Nasa Data
      </a>
    </div>
    <div style={{ gridRow: 3, gridColumn: 2 }}> Global Landslide Polygons</div>
    <div style={{ gridRow: 3, gridColumn: 3 }}> Dataset created by Emanuel Büechi </div>
    <div style={{ gridRow: 3, gridColumn: 4 }}> Regularly updated</div>
    <div style={{ gridRow: 3, gridColumn: 5 }}>
      <a
        href="https://blogs.agu.org/landslideblog/2015/05/11/gorkha-earthquake-update/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Dave Petley&apos;s Landslide Blog
      </a>
    </div>
    <div style={{ gridRow: 4 / 9, gridColumn: 1 }}> Local Landslide Inventory</div>
    <div style={{ gridRow: 4, gridColumn: 2 }}> Nepal 2015</div>
    <div style={{ gridRow: 4, gridColumn: 3 }}> Landslides which happened after the Gorkha Earthquake 2015 </div>
    <div style={{ gridRow: 4, gridColumn: 4 }}> 2015 or Younger</div>
    <div style={{ gridRow: 4, gridColumn: 5 }}>
      <a
        href="https://blogs.agu.org/landslideblog/2015/05/11/gorkha-earthquake-update/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Landslide Blog
      </a>
    </div>
    <div style={{ gridRow: 5, gridColumn: 2 }}> Japan 2016</div>
    <div style={{ gridRow: 5, gridColumn: 3 }}> Landslides which happened after the Kumamoto earthquake 2016 </div>
    <div style={{ gridRow: 5, gridColumn: 4 }}> 2016 or younger</div>
    <div style={{ gridRow: 5, gridColumn: 5 }}>
      <a
        href="https://blogs.agu.org/landslideblog/2015/05/11/gorkha-earthquake-update/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Landslide Blog
      </a>
    </div>
    <div style={{ gridRow: 6, gridColumn: 2 }}> El Salvador 2001</div>
    <div style={{ gridRow: 6, gridColumn: 3 }}> Landslides that happened after an earthquake in February 2001 </div>
    <div style={{ gridRow: 6, gridColumn: 4 }}> 2010</div>
    <div style={{ gridRow: 6, gridColumn: 5 }}>
      <a
        href="https://www.sciencebase.gov/catalog/item/5874cd27e4b0a829a320bbe3"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ministerio de Medio Ambientey Recursos Naturales
      </a>
    </div>
    <div style={{ gridRow: 7, gridColumn: 2 }}> Cordillera Blanca</div>
    <div style={{ gridRow: 7, gridColumn: 3 }}>
      Peruvian Lanskide inventory of Cordillera Blanca as established by Emmanuel for his Master Thesis
    </div>
    <div style={{ gridRow: 7, gridColumn: 4 }}> 2018</div>
    <div style={{ gridRow: 7, gridColumn: 5 }}>
      <a href="https://link.springer.com/article/10.1007/s10346-018-1090-1" target="_blank" rel="noopener noreferrer">
        Bueechi et al 2018
      </a>
    </div>
    <div style={{ gridRow: 8, gridColumn: 2 }}> Austria_Hora</div>
    <div style={{ gridRow: 8, gridColumn: 3 }}>
      Landslide inverntory of the Natural Hazard Overview &amp; Risk assessment Austria (HORA)
    </div>
    <div style={{ gridRow: 8, gridColumn: 4 }}>reguarly updated</div>
    <div style={{ gridRow: 8, gridColumn: 5 }}>
      <a href="https://www.hora.gv.at/" target="_blank" rel="noopener noreferrer">
        HORA
      </a>
    </div>
    <div style={{ gridRow: 9, gridColumn: 1 }}>Slope</div>
    <div style={{ gridRow: 9, gridColumn: 2 }}>InterMap 30 m DEM</div>
    <div style={{ gridRow: 9, gridColumn: 3 }}> The Intermap DEM with 30m resolution was used for computation </div>
    <div style={{ gridRow: 9, gridColumn: 4 }} />
    <div style={{ gridRow: 9, gridColumn: 5 }}>
      <a href="https://www.intermap.com/" target="_blank" rel="noopener noreferrer">
        intermap
      </a>
    </div>
    <div style={{ gridRow: 10, gridColumn: 1 }}>Geology</div>
    <div style={{ gridRow: 10, gridColumn: 2 }}>GLiM</div>
    <div style={{ gridRow: 10, gridColumn: 3 }}> Glim: Global Lithology Map, University of Hamburg </div>
    <div style={{ gridRow: 10, gridColumn: 4 }}>2015</div>
    <div style={{ gridRow: 10, gridColumn: 5 }}>
      <a
        href="https://swissrecsm.service-now.com/#viewer.do?sysparm_stack=no&amp;sysparm_sys_id=a11745e887858510e3370f690cbb35e0&amp;view=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        GLiM
      </a>
      hosted by
      <a
        href="https://ccgm.org/en/home/168-lithological-map-of-the-world-9782917310250.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        CGMW
      </a>
    </div>
    <div style={{ gridRow: 11, gridColumn: 1 }}>Earthquake Risk</div>
    <div style={{ gridRow: 11, gridColumn: 2 }}>Internal EQ-Layer</div>
    <div style={{ gridRow: 11, gridColumn: 3 }}>Model developed internally</div>
    <div style={{ gridRow: 11, gridColumn: 4 }}>2015</div>
    <div style={{ gridRow: 11, gridColumn: 5 }}>
      <a
        href="https://catnet.swissre.com/?mapBounds=-188.9591193,-46.6545107,241.3533807,78.3922123&amp;activeLayers=GEOPORTAL_SHARED:Seismic_Hazard"
        target="_blank"
        rel="noopener noreferrer"
      >
        Catnet
      </a>
    </div>
    <div style={{ gridRow: 12, gridColumn: 1 }}>Rainfall Risk</div>
    <div style={{ gridRow: 12, gridColumn: 2 }}>Open Weather Map</div>
    <div style={{ gridRow: 12, gridColumn: 3 }}>
      Relevant since water-content in soil can be a decisive triggering factor
    </div>
    <div style={{ gridRow: 12, gridColumn: 4 }} />
    <div style={{ gridRow: 12, gridColumn: 5 }}>
      Internal Layer can be found
      <a
        href="https://swissrecsm.service-now.com/#viewer.do?sysparm_stack=no&amp;sysparm_sys_id=a11745e887858510e3370f690cbb35e0&amp;view=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
    </div>
  </div>
);

export const LightningDefinition = () => (
  <>The global lightning hazard layer shows the mean annual flash rate per square kilometer.</>
);
export const LightningSources = () => (
  <ul>
    <li>NASA Earth Science Data and Information System (ESDIS) Project</li>
    <li>Global Hydrology Resource Centre (GHRC)</li>
    <li>Distributed Active Archive Centre (DAAC)</li>
  </ul>
);

export const PluvialFloodDefinition = () => (
  <>
    Swiss Re&apos;s Global Pluvial Flood Zones provide information about the extent and frequency of flooding due to
    direct rainfall, minor channel and flash flooding. The zones are available worldwide (from 60°S to 60°N) at the high
    resolution of 10 meters in USA and Europe and 30 meters for the rest of the world.
  </>
);
export const PluvialFloodSources = () => (
  <ul>
    <li>
      Copernicus Climate Change Service (C3S) (2018):
      <i>ERA5: Fifth generation of ECMWF atmospheric reanalyses of the global climate</i>. Copernicus Climate Change
      Service Climate Data Store (CDS), accessed June 2020,
      <a href="https://cds.climate.copernicus.eu/cdsapp#!/home" target="_blank" rel="noopener noreferrer">
        https://cds.climate.copernicus.eu/cdsapp#!/home
      </a>
    </li>
    <li>
      Guidolin, M., Chen, A. S., Ghimire, B., Keedwell, E. C., Djordjević, S., &amp; Savić, D. A. (2016). A weighted
      cellular automata 2D inundation model for rapid flood analysis. <i>Environmental Modelling &amp; Software</i> 84,
      378-394.
    </li>
    <li> Intermap 10 and 30m digital elevation model. </li>
    <li>
      NOAA Atlas 14 (2018): <i>Precipitation-Frequency Atlas of the United States</i>. NOAA&apos;s National Weather
      Service, accessed June 2020,
      <a href="https://www.nws.noaa.gov/oh/hdsc/index.html" target="_blank" rel="noopener noreferrer">
        https://www.nws.noaa.gov/oh/hdsc/index.html
      </a>
    </li>
    <li>
      Ross, C.W., L. Prihodko, J.Y. Anchang, S.S. Kumar, W. Ji, and N.P. Hanan. 2018. Global Hydrologic Soil Groups
      (HYSOGs250m) for Curve Number-Based Runoff Modeling. ORNL DAAC, Oak Ridge, Tennessee, USA.
      <a href="https://doi.org/10.3334/ORNLDAAC/1566" target="_blank" rel="noopener noreferrer">
        https://doi.org/10.3334/ORNLDAAC/1566
      </a>
    </li>
    <li>
      U.S. Geological Survey. <i>National Hydrography Dataset</i>.
    </li>
  </ul>
);

export const StormSurgeDefinition = () => (
  <>
    Swiss Re&apos;s Global Storm Surge Zones provide information about the frequency of flooding due to storm surge from
    the ocean. The zones are available worldwide (from 60°S to 60°N) and cover all the ocean coastlines (except for the
    Black Sea and the Caspian Sea)
  </>
);
export const StormSurgeSources = () => (
  <ul>
    <li>Intermap 30m digital terrain model</li>
    <li> C-GLORS Global Ocean Reanalysis, using E.U. Copernicus Marine Service Information </li>
    <li> Global Water Occurrence Layer (Jean-Francois Pekel, Andrew Cottam, Noel Gorelick, Alan S. Belward, </li>
    <li>
      High-resolution mapping of global surface water and its long-term changes. Nature 540, 418-422 (2016).
      (doi:10.1038/nature20584))
    </li>
  </ul>
);

export const TornadoDefinition = () => (
  <>
    The hazard map consists of three parts with different data granularity:
    <strong>United States & Canada</strong>
    <br />
    Data represents the average yearly tornado occurrence (F2-F5) within a grid cell of 50km x 50km based on 64
    observation years and 30 years respectively <br />
    <strong>Rest of the world</strong>
    <br />
    Data for the calculation was derived from numerous scientific documentations, observations and expert knowledge
  </>
);
export const TornadoSources = () => (
  <ul>
    <li>
      <strong>USA:</strong> data from NOAA&apos;s Storm Prediction Center (SPC), NOAA&apos;s National Hurricane Center
    </li>
    <li>
      <strong>Canada:</strong> Paper from &apos;Environment Canada&apos; (David Sills)
    </li>
    <li>
      <strong>Rest of the World:</strong> combination of the knowledge of Swiss Re&apos;s Atmospheric Perils
      Specialists, own interpretations of tornado models, recent event observations
    </li>
  </ul>
);

export const TsunamiDefinition = () => (
  <>
    Calculated Swiss Re tsunami hazard zones in CatNet® are available for all countries in the pacific basin on a 30
    meter resolution, reflecting the Tsunami hazard in a near-global consistent manner.
  </>
);
export const TsunamiSources = () => (
  <ul>
    <li>Swiss Re proprietary models; NCTR Propagation Database by the NOAA Center for Tsunami Research</li>
    <li>
      Historic earthquake catalogues (NEIC, Centennial); Swiss Re global 30 m resolution digital elevation model and the
      Global Surface Water dataset (Jean-Francois Pekel, 2016)
    </li>
  </ul>
);

export const WindstormDefinition = () => (
  <>The wind speed data shows the 3 seconds peak gust with a return period of 50 years.</>
);
export const WindstormSources = () => (
  <ul>
    <li> Hazard module of Swiss Re&apos;s proprietary wind loss models; Global reanalysis dataset </li>
    <li>
      &apos;20<sup>th</sup> century reanalysis project&apos; designed by the Physical Sciences Division of the Earth
      System Laboratory of NOAA
    </li>
  </ul>
);

export const FluvialFloodSelectedOfficialZonesDefinition = () => (
  <>
    Country-wide flood maps by official/governmental entities (see Data Sources). Depending on the source, different
    return periods are available and mapped to Swiss Re&apos;s scale
  </>
);
export const FluvialFloodSelectedOfficialZonesSources = () => (
  <ul>
    <li> Intermap&apos;s NEXTMap World 30 digital elevation model on 30m resolution. </li>
    <li> SRTM digital surface model (NASA, USA, approx. 90m resolution). </li>
    <li>
      Jean-Francois Pekel, Andrew Cottam, Noel Gorelick, Alan S. Belward, High-resolution mapping of global surface
      water and its long-term changes. Nature 540, 418-422 (2016). (doi:10.1038/nature20584).
    </li>
  </ul>
);

export const HailstormDefinition = () => (
  <>
    The expected number of hail days per year with a hail diameter larger than 2 centimeters related to an area 50km x
    50km is shown.
  </>
);
export const HailstormSources = () => (
  <>
    Scientific literature about the global and regional climatological distribution of hail frequency and severity;
    Swiss Re&apos;s internal claims and hail model data; reports of severe hail events; expert judgement of Swiss
    Re&apos;s Atmospheric Peril Specialists.
  </>
);

export const VolcanoAshTicknessDefinition = () => (
  <>
    The global map shows the volcanic hazard, represented as the local ash thickness around volcanoes (150km) from a
    major eruption with a return period of 475y.
  </>
);
export const VolcanoAshTicknessSources = () => (
  <ul>
    <li>SR Models Swiss Re proprietary</li>
    <li> Global Volcanism Program, 2013. Volcanoes of the World, v. 4.4.1. Venzke, E (ed.). </li>
    <li>
      Smithsonian Institution. Downloaded 9th July 2015.(
      <a target="_blank" href="http://volcano.si.edu/" rel="noopener noreferrer">
        http://volcano.si.edu/
      </a>
      )
    </li>
    <li>
      Gonzalez-Mellado, A. O., &amp; Cruz-Reyna, S. (2010): A simple semi-empirical approach to model thickness of
      ash-deposits for different eruption scenarios. Natural Hazards and Earth System Science, 10(11), 2241-2257.
    </li>
    <li>
      Jenkins, S., Magill, C., McAneney, J., &amp;Blong, R. (2012): Regional ash fall hazard I: a probabilistic
      assessment methodology. Bulletin of volcanology, 74(7), 1699-1712.
    </li>
    <li>
      Loughlin, S., Sparks, S., Brown, S., Jenkins, S., &amp; Vye-Brown, C. (Eds.). (2015). Global Volcanic Hazards and
      Risk. Cambridge University Press.
    </li>
    <li>
      Mastin, L. G., Guffanti, M., Servranckx, R., Webley, P., Barsotti, S., Dean, K., ... &amp; Waythomas, C. F.
      (2009): A multidisciplinary effort to assign realistic source parameters to models of volcanic ash-cloud transport
      and dispersion during eruptions. Journal of Volcanology and Geothermal Research, 186(1), 10-21.
    </li>
    <li>
      Mead, S., &amp; Magill, C. (2014): Determining change points in data completeness for the Holocene eruption
      record. Bulletin of Volcanology, 76(11), 1-14.
    </li>
    <li>
      Newhall, C. G., &amp; Self, S. (1982): The volcanic explosivity index/VEI/ - An estimate of explosive magnitude
      for historical volcanism. Journal of Geophysical Research, 87(C2), 1231-1238.
    </li>
  </ul>
);
